import React  from 'react';

import {Button} from 'primereact/button';

import {
  Answer,
  Location,
  Question,
} from '../../../state-management/types';
import {MapDirectionsButton} from '../..';

import './styles.scss';

interface TripQuestionComponentProps {
  question: Question
  location?: Location
  answer?: Answer
  onSelect?: (questionId: number, selectedAnswer: string, answer?: Answer) => void
}

const TripQuestionComponent: React.FC<TripQuestionComponentProps> = ({
  location,
  question,
  answer,
  onSelect,
})=> {
  
  const hasArrived = answer?.answer?.toLowerCase() === 'arrived'
  let icon = hasArrived ? 'check' : 'question'
  
  return (
    <>
      <MapDirectionsButton location={location} />
      <Button
        id="confirm-arrival-btn"
        label="Confirm Arrival"
        icon={`pi pi-${icon}`}
        className="p-button-rounded p-button-success mr-2"
        onClick={() => {
          if (question?.id)
            onSelect?.(question?.id, 'arrived', answer)
        }}
      />
    </>
  )
};

export default TripQuestionComponent;
