import { createContext } from 'react'
import { JobDispatch } from './reducer'
import {
  Answer,
  Boat,
  Job, Location, MasterTask,
  Question,
  Task,
} from '../types'

export interface JobState {
  job?: Job
  masterTasks: MasterTask[]
  
  phases: JobPhase[]
  phase: JobPhase
  
  currentBoat?: Boat
  
  currentTask?: Task
  currentQuestion?: Question
  currentQuestionIndex: number
  
  storedTasks: Map<string, Map<number, any>>
  
  skipReason?: string
  arrivedAtLaunch?: boolean
  completed?: boolean
  
  error: any
  loading: boolean
}

export interface JobPhase {
  index: number
  name: string
  currentMasterTaskIndex: number
  masterTasks: MasterTask[]
  tasks: Task[]
  location?: Location
  nextLocation?: Location
}

export const isPhaseComplete = (phase: JobPhase): boolean => {
  const hasTasks = phase?.tasks?.length > 0 && (phase.tasks?.length === phase.masterTasks?.length);
  const allTasksComplete = phase?.tasks?.every(task => Boolean(task.completed_at));
  return hasTasks && allTasksComplete;
}


export interface JobContextState {
  state: JobState
  dispatch: JobDispatch
}

export const defaultState = (): JobState => ({
  job: undefined,
  masterTasks: [],
  phases: [],
  phase: {
    index: 0,
    name: '',
    currentMasterTaskIndex: 0,
    masterTasks: [],
    tasks: [],
  },
  currentBoat: undefined,
  
  currentTask: undefined,
  currentQuestionIndex: 0,
  currentQuestion: undefined,
  
  storedTasks: new Map(),
  
  arrivedAtLaunch: false,
  skipReason: '',
  completed: false,
  
  error: '',
  loading: false,
})

export const Context = createContext<JobContextState>({
  state: defaultState(),
  dispatch: () => null,
})
