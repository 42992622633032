
export const toTitleCase = (str?: string) => {
  if (!str)
    return ''
  
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const formatPluralSuffix = (word: string) => {
  if (word.endsWith('s')) {
    return `${word}es`
  }
  
  return `${word}s`
}

