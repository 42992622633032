import dayjs from 'dayjs';

export const check = (query?: string | number | null): boolean =>
  query !== null &&
  query !== undefined &&
  query !== -1 &&
  query !== '-1' &&
  query !== 'All' &&
  query !== 'undefined' &&
  query !== 'null' &&
  query !== '';

export const LIFECYCLE = check(window.LIFECYCLE)
  ? window.LIFECYCLE
  : 'local';

export const getCurrentUrl = (port?: number): string => {
  if (!port) port = 8000
  
  if (window.location.hostname === 'localhost') return `http://localhost:${port}/`;
  
  return encodeURI(`https://${window.location.hostname}`);
};

const getApiUrl = (): string => {
  let url = window.API_URL
  if (!check(url)) url = getCurrentUrl()
  
  return url + 'api/'
}

export const API_URL = getApiUrl()

export const APP_CODE = check(window.APP_CODE)
  ? window.APP_CODE
  : 'boatload';

export const GOOGLE_CLIENT_ID = '50336065372-urmm0ikcgqamger49gssluju3kfan309.apps.googleusercontent.com'

export const PUBLIC_BUCKET = check(window.PUBLIC_BUCKET)
  ? window.PUBLIC_BUCKET
  : `dev-${APP_CODE}-v3-public-content`

export const GOOGLE_MAPS_KEY = check(window.GOOGLE_MAPS_KEY)
  ? window.GOOGLE_MAPS_KEY
  : 'AIzaSyBi8c_FjiOPkerecsw2u4hmqjuIhwNQJQM';

export const titleCaseWords = (s: string) => {
  return s.replace(/\b[a-z]/g, (letter: string) => letter.toUpperCase())
}

export const getPlaceholder = (label: string) => {
  return titleCaseWords(label?.replace('-', ' '))
}

export const validateEmail = (emailStr: string) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailStr))
    return true
  
  return false
}

export const validatePhone = (phoneStr: string) => {
  if(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phoneStr))
    return true
  
  return false
}

// validate that a number is a 6 digit auth code
export const validateCode = (codeStr: string) => {
  if(/^\d{6}$/.test(codeStr))
    return true
  
  return false
}


export const formatDate = (date?: string) => {
  if (!date) return
  return dayjs(date).format('MMM D, YYYY')
}

export const formatLocalTime = (time?: string) => {
  if (!time) return
  
  if (!dayjs(time, 'h:mm A').isValid()) {
    time = `01/01/1970 ${time}`
    time = new Date(time).toLocaleString()
  }
  
  const formattedTime = dayjs(time).format('h:mm A')
  if (formattedTime === 'Invalid Date')
    return 'Invalid Time'
  
  return formattedTime
}

const getTimeDiffStr = (num: number, str: string) => {
    let returnStr = str
    if (num !== 1)
      returnStr += 's'
    return `${num} ${returnStr} ago`
}

export const getTimeDiff = (created?: string) => {
  if (!created) return
  
  const dateCreated = new Date(created)
  const today = new Date()
  const diff = today.getTime() - dateCreated.getTime();
  
  let msec = diff;
  const yy = Math.floor(msec / 1000 / 60 / 60 / 24 / 30 / 365.25);
  msec -= yy * 1000 * 60 * 60 * 24 * 30 * 365.25;
  const mo = Math.floor(msec / 1000 / 60 / 60 / 24 / 30);
  msec -= mo * 1000 * 60 * 60 * 24 * 30;
  const dd = Math.floor(msec / 1000 / 60 / 60 / 24);
  msec -= dd * 1000 * 60 * 60 * 24;
  const hh = Math.floor(msec / 1000 / 60 / 60);
  msec -= hh * 1000 * 60 * 60;
  const mm = Math.floor(msec / 1000 / 60);
  msec -= mm * 1000 * 60;
  const ss = Math.floor(msec / 1000);
  
  if (yy > 0) {
    return getTimeDiffStr(yy, 'year')
  }
  if (mo > 0) {
    return getTimeDiffStr(mo, 'month')
  }
  if (dd > 0) {
    return getTimeDiffStr(dd, 'day')
  }
  if (hh > 1) {
    return getTimeDiffStr(hh, 'hour')
  }
  if (mm > 1) {
    return getTimeDiffStr(mm, 'minute')
  }
  return getTimeDiffStr(ss, 'second')
  
}


export const getError = (data: any): string => {
  if (data?.non_field_errors?.length) {
    return JSON.stringify(data.non_field_errors[0]);
  }
  if (data?.error) return data.error;
  if (data?.detail) return data.detail;
  if (data?.username?.length > 0) return data.username[0];
  if (data?.new_password2) return data.new_password2;

  if (data) return JSON.stringify(data);
  return data
};

export const fixURI = (url: string): string => {
  let ret = url;
  if (!url.includes('localhost')) ret = url.replace('http', 'https');
  return encodeURI(ret);
};


export const publicBucketUrl = `https://storage.googleapis.com/${PUBLIC_BUCKET}`;
