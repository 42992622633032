import {useContext} from 'react';
import {ApolloError, useQuery} from '@apollo/client';

import {Context} from './context';
import {Answer, Boat, MasterTask} from '../types';
import {
  GET_JOB,
  useAlert,
  useAnswers,
  useTasks,
} from '../../hooks';
import {useJobs} from '../../hooks/useJobs';
import {
  ADD_TASK,
  COMPLETE_TASK,
  ERROR_JOB,
  SET_PHASE,
  NEXT_PHASE,
  NEXT_TASK,
  PREV_PHASE,
  PREV_TASK,
  SET_ACTIVE_BOAT,
  START_JOB,
  STORE_ANSWER,
  SUBMIT_ANSWER,
} from './reducer';

export interface ResponseOptions {
  onCompleted?: (res: any) => void,
  onError?: (e: ApolloError) => void
}

export const useJob = (id?: number) => {
  const { state, dispatch } = useContext(Context);
  
  const { showSuccess, showError } = useAlert();
  
  const {upsertJob} = useJobs()
  const {upsertTask} = useTasks()
  const {upsertAnswer, uploadFile} = useAnswers()
  
  useQuery(GET_JOB, {
    skip: !Boolean(id) || state.job?.id === id,
    variables: { id },
    onCompleted: ({ job }) => {
      setTimeout(() => {
        dispatch({
          type: START_JOB,
          job: job,
        });
      }, 500);
    },
    onError: (error: ApolloError) => {
      showError(error.graphQLErrors[0].message);
    }
  });
  
  const setPhase = (index: number) => {
    dispatch({
      type: SET_PHASE,
      index,
    });
  }
  
  const nextPhase = () => {
    dispatch({
      type: NEXT_PHASE,
    })
  }
  
  const prevPhase = () => {
    dispatch({
      type: PREV_PHASE,
    })
  }
  
  const nextTask = () => {
    dispatch({
      type: NEXT_TASK,
    })
  }
  
  const prevTask = () => {
    dispatch({
      type: PREV_TASK,
    })
  }
  
  const setActiveBoat = (boat: Boat) => {
    dispatch({
      type: SET_ACTIVE_BOAT,
      boat
    })
  }
  
  const skipJob = async (skipReason: string, options: ResponseOptions) => {
    const input = {
      id: state.job?.id,
      skip_reason: skipReason,
    }
    await upsertJob({
      variables: { input },
      onCompleted: () => {
        options?.onCompleted?.(input)
        dispatch({
          type: 'SKIP_JOB',
          skipReason,
        })
        showSuccess('Job skipped')
      },
      onError: (e: any) => {
        console.error(e)
        options?.onError?.(e)
        dispatch({
          type: 'ERROR_JOB',
          error: 'Unable to skip job'
        })
      }
    });
  }
  
  const markReturnInProgress = async () => {
    if (state.job?.arrived_at_pickup)
      return;
    
    const input = {
      id: state.job?.id,
      arrived_at_pickup: true
    }
    return upsertJob({
      variables: { input },
      onCompleted: () => {
        dispatch({
          type: 'ARRIVED_AT_LAUNCH'
        })
        dispatch({
          type: 'NEXT_PHASE',
        })
      },
      onError: (e: any) => {
        console.error(e)
        dispatch({
          type: 'ERROR_JOB',
          error: 'Unable to mark return in progress'
        })
      }
    })
  }
  
  const startTask = async (masterTask: MasterTask) => {
    if (!masterTask?.id)
      throw new Error('Unable to start task: Missing master task id')
    
    console.log('starting task', masterTask?.id)
    
    const input = {
      job_id: state.job?.id,
      boat_id: state.currentBoat?.id,
      master_task_id: masterTask?.id,
    }
    return upsertTask({
      variables: { input },
      onCompleted: ({ upsertTask: task }) => {
        console.log('upserted task', task)
        dispatch({
          type: ADD_TASK,
          task,
        });
      },
      onError: (e: any) => {
        console.error(e);
      }
    });
  }
  
  const taskKey = () => {
    return `${state.currentBoat?.id}-${state.phase.masterTasks[state.phase.currentMasterTaskIndex]?.id}`
  }
  
  const submitTask = async () => {
    let storedAnswers = state.storedTasks.get(taskKey());
    
    if (!storedAnswers)
      return;
    
    let submitted = [];
    try {
      const keys = storedAnswers.keys();
      for (let i = 0; i < storedAnswers.size; i++) {
        const questionId = keys.next().value;
        const { selectedAnswer, answer, image } = storedAnswers.get(questionId);
        const res = await submitAnswer(questionId, selectedAnswer, answer, image);
        console.log('res?.data?.upsertAnswer', res?.data?.upsertAnswer)
        submitted.push(res?.data?.upsertAnswer);
      }
    } catch (e) {
      console.error('error submitting answers', e)
    }
    
    let answers = state.currentTask?.answers ?? [];
    const numAnswers = answers.length + submitted.length;
    
    if (numAnswers === state.phase.masterTasks[state.phase.currentMasterTaskIndex]?.questions?.length) {
      await completeTask();
      nextTask();
    }
  };
  
  const completeTask = async () => {
    console.log('completing task', state.currentTask)
    
    if (!state.currentTask?.id)
      throw new Error('Missing task id')
    
    const input = {
      id: state.currentTask?.id,
      completed_at: new Date()
    }
    
    return upsertTask({
      variables: { input },
      onCompleted: ({ upsertTask: task }) => {
        dispatch({
          type: COMPLETE_TASK,
          completedAt: task?.completed_at,
        });
      },
      onError: (e: ApolloError) => {
        console.error(e);
        dispatch({
          type: ERROR_JOB,
          error: 'Unable to mark task as completed'
        });
      }
    });
  }
  
  const storeAnswer = async (questionId?: number, selectedAnswer?: string, answer?: Answer, image?: File): Promise<any> => {
    if (!questionId)
      throw new Error('Missing question id')
    if (!selectedAnswer && !image)
      throw new Error('Missing answer')
    
    dispatch({
      type: STORE_ANSWER,
      questionId,
      selectedAnswer,
      answer,
      image,
    });
  }
  
  const submitAnswer = async (questionId?: number, selectedAnswer?: string, answer?: Answer, image?: File): Promise<any> => {
    if (!questionId)
      throw new Error('Missing question id')
    if (!selectedAnswer && !image)
      throw new Error('Missing answer')
    if (!state.currentTask?.id)
      throw new Error('Missing task id')
    
    let answerRes
    if (image) {
      const res = await uploadFile(image, state.currentTask?.id, questionId).catch((e: any) => console.error(e))
      if (res?.answer) {
        answerRes = res.answer
        answerRes.signed_url = res.signed_url
      }
    } else {
      const input = {
        id: answer?.id,
        task_id: state.currentTask?.id,
        question_id: questionId,
        answer: selectedAnswer,
      };
      
      return upsertAnswer({
        variables: { input },
        onCompleted: ({ upsertAnswer: answer }) => {
          dispatch({
            type: SUBMIT_ANSWER,
            answer,
          });
        },
        onError: (e: any) => {
          console.error(e)
          dispatch({
            type: ERROR_JOB,
            error: 'Unable to submit answer'
          })
        }
      });
    }
    
    return answerRes
  }
  
  const completeJob = async (): Promise<any> => {
    const input = {
      id: state.job?.id,
      completed_at: new Date(),
      return_in_progress: false
    };
    return upsertJob({
      variables: { input },
      onCompleted: () => {
        dispatch({
          type: 'COMPLETE_JOB',
        });
      },
      onError: (e: ApolloError) => {
        console.error(e);
        dispatch({
          type: 'ERROR_JOB',
          error: 'Unable to mark job as completed'
        });
      }
    });
  }
  
  return {
    setPhase,
    nextPhase,
    prevPhase,
    nextTask,
    prevTask,
    setActiveBoat,
    skipJob,
    markReturnInProgress,
    startTask,
    submitTask,
    completeTask,
    storeAnswer,
    submitAnswer,
    completeJob,
    taskKey,
    state,
  };
};
