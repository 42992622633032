import { Dispatch } from 'react'
import { AuthState, defaultState } from './context'
import { getError } from '../../utils'
import {
  Marina,
  User,
} from '../types'
import LogRocket from 'logrocket'

export type AuthAction =
  | { type: 'SET_AUTHENTICATION'; token: string, user: User }
  | { type: 'SET_ACTIVE_MARINA'; marina: Marina }
  | { type: 'START_AUTHENTICATION' }
  | { type: 'ERROR_AUTHENTICATION'; error: string }
  | { type: 'CLEAR_AUTHENTICATION' }
  | { type: 'RESET_AUTHENTICATION' }

export type AuthDispatch = Dispatch<AuthAction>

export const reducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case 'START_AUTHENTICATION': {
      return { ...state, error: '', loading: true }
    }

    case 'ERROR_AUTHENTICATION': {
      const { error } = action
      const parsedError = getError(error)
      return { ...state, error: parsedError, loading: false }
    }
    
    case 'SET_AUTHENTICATION': {
      const { token, user} = action
      
      const exp = new Date(new Date().getTime() + 3600 * 1000).toString()
      if (exp) sessionStorage.setItem('exp', exp)
      
      if (user) {
        sessionStorage.setItem('user', JSON.stringify(user))
        LogRocket.identify(String(user.id), {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email || '',
        });
      }
      
      if (token !== '') sessionStorage.setItem('token', token)

      return {
        ...state,
        error: '',
        loading: false,
        isAuthenticated: true,
        user,
        exp,
      }
    }

    case 'CLEAR_AUTHENTICATION': {
      sessionStorage.clear()
      return defaultState()
    }

    case 'RESET_AUTHENTICATION': {
      const token = sessionStorage.getItem('token')

      const user = JSON.parse(
        String(sessionStorage.getItem('user') || {})
      ) as User
      
      const exp = sessionStorage.getItem('exp') || ''
      
      return {
        ...state,
        user,
        exp,
        isAuthenticated: Boolean(token)
      }
    }
    
    case 'SET_ACTIVE_MARINA': {
      const { marina } = action
      return {
        ...state,
        activeMarina: marina
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}
