import * as React from 'react';

import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import Divider from '@mui/material/Divider';
import {Stack} from '@mui/material';

import {TbSpeedboat} from 'react-icons/tb';
import {CiClock2} from 'react-icons/ci';
import {CiLocationOn} from 'react-icons/ci';
import {BsFillPersonVcardFill} from 'react-icons/bs';
import {RiRouteFill} from 'react-icons/ri';

import {Boat, Job} from '../../state-management/types';
import {DateTime, Grid, TruckComponent} from '../index';
import './styles.scss'

interface JobCardProps {
  job: Job
  orderNumber?: number
  onClick?: () => void
  onEdit?: () => void
  disabled?: boolean | false
  allowEdit?: boolean | false
}

const JobCardComponent: React.FC<JobCardProps> = ({ orderNumber, job, onClick, allowEdit, onEdit, disabled }) => {
  
  const handleOnClick = () => {
    if (!disabled && onClick && typeof onClick === 'function') {
      onClick();
    }
  }
  
  const startButton = (
    <Button
      id="job-card-start-btn"
      icon="pi pi-play"
      className="p-button-success mr-2"
      label="Start"
      onClick={handleOnClick}
    />
  )
  
  const editButton = (
    <Button
      id="job-card-edit-btn"
      icon="pi pi-pencil"
      className="p-button-warning mr-2"
      label="Edit"
      onClick={onEdit}
    />
  )
  
  const header = (
    <Stack direction='row' spacing={2}>
      <Stack>
        <h5>{orderNumber}. {job?.customer?.full_name}</h5>
      </Stack>
      
      <Stack>
        {job?.boats?.map((boat: Boat) => (
          <h5 key={boat?.id} className="job-card--boat-name">
            <TbSpeedboat className='mr-2'/>
            {boat?.name}
          </h5>
        ))}
      </Stack>
    </Stack>
  )
  
  const subHeader = (
    <div className="job-card--header">
      <CiLocationOn className='mr-2'/>
      {job?.customer?.location?.full_address || 'N/A'}
      <Divider className='mt-3' color='white'/>
    </div>
  )
  
  const assignmentTemplate = (
    <div className="job-card--text">
      <h4>
        <BsFillPersonVcardFill className='mr-2'/>
        {job?.driver ? (
          <>
            {job?.driver?.full_name}
          </>
        ) : (
          <>
            Driver not assigned
          </>
        )}
      </h4>
      {job?.passenger && (
        <h4>
          <BsFillPersonVcardFill className='mr-2'/>
          {job?.passenger?.full_name}
        </h4>
      )}
    </div>
  )
  
  const scheduleTemplate = (
    <DateTime
      icons
      date={job?.appointment_date}
      time={job?.appointment_time}
      showTime={!job?.daily_order}
    />
  )
  
  return (
    <Card
      id='job-card'
      className='job-card'
      title={header}
      subTitle={subHeader}
    >
      <Grid
        items={[
          {
            id: 'type',
            cols: {xs: 5, sm: 5, md: 6, lg: 6},
            content: (
              <h4>
                <RiRouteFill className='mr-2'/>
                {job?.job_type?.name}
              </h4>
            ),
            rows: 1,
          },
          {
            id: 'assignment',
            cols: {xs: 7, sm: 7, md: 6, lg: 6},
            content: assignmentTemplate,
            rows: 1,
          },
          {
            id: 'truck',
            cols: {xs: 7, sm: 7, md: 6, lg: 6},
            content: <TruckComponent icons truck={job.truck} />,
            rows: 1,
          },
          {
            id: 'appointment',
            cols: {xs: 5, sm: 5, md: 6, lg: 6},
            content: scheduleTemplate,
            rows: 1,
          },
        ]}
      />
      
      <Stack direction='row' spacing={4}>
        <Stack>
          {startButton}
        </Stack>
        
        {allowEdit && (
          <Stack>
            {editButton}
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default JobCardComponent;
