import React, {useEffect, useRef, useState} from 'react';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {OrderList} from 'primereact/orderlist';
import {Toast} from 'primereact/toast';

import {useJobs} from '../../hooks/useJobs';
import {useTrucks} from '../../hooks/useTrucks';
import {Job, Truck} from '../../state-management/types';
import {LIFECYCLE} from '../../utils';
import {DropdownComponent, Loader, Message, TextField} from '..';


interface JobsOrderListProps {
  jobs: Job[];
  onSave?: () => void;
}

const JobsOrderList: React.FC<JobsOrderListProps> = ({ jobs, onSave  }) => {
  const toast = useRef(null);
  
  const [loading, setLoading] = useState(false);
  
  const [date, setDate] = useState<Date>();
  const [truck, setTruck] = useState<Truck>();
  const [items, setItems] = useState<Job[]>([]);
  
  const {upsertJob} = useJobs();
  const {trucks} = useTrucks();
  
  useEffect(() => {
    let tmpJobs = [...jobs];
    for (let i = 0; i < tmpJobs.length; i++) {
      const job = {...jobs[i]};
      job.daily_order = i + 1;
      tmpJobs[i] = job;
    }
    setItems(tmpJobs);
  }, [jobs]);
  
  const handleSave = async () => {
    if (!date) {
      // @ts-ignore
      toast.current.show({severity: 'error', summary: 'Error', detail: 'Please select a date', life: 3000});
      return;
    }
    
    if (!truck) {
      // @ts-ignore
      toast.current.show({severity: 'error', summary: 'Error', detail: 'Please select a truck', life: 3000});
      return;
    }
    
    setLoading(true);
    
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const input = {
        id: item.id,
        truck_id: truck.id,
        daily_order: i + 1,
        appointment_date: date,
      }
      await upsertJob({
        variables: { input },
        onCompleted: () => {
          // @ts-ignore
          toast.current.show({severity: 'success', summary: 'Success', detail: 'Job Order Set', life: 3000});
          setItems([]);
          setDate(undefined);
          
          if (onSave && typeof onSave === 'function')
            onSave();
          
          if (i === items.length - 1)
            setLoading(false);
        },
        onError: () => {
          // @ts-ignore
          toast.current.show({severity: 'error', summary: 'Error', detail: 'Setting Job Order', life: 3000});
          setLoading(false);
        }
      });
    }
  }
  
  const itemTemplate = (item: Job) => {
    return (
      <div id={`jobs-order-list-item-${item.daily_order}`} className="flex flex-wrap align-items-center gap-3">
        <div className="flex-1 flex flex-column gap-2 xl:mr-8">
          <span className="font-bold">
            {item.customer?.full_name}
          </span>
        </div>
        
        <div className="flex align-items-center gap-2">
          <span>{item.job_type?.name}</span>
        </div>
        {item.boats?.map((boat, index) => (
          <div key={index} className="flex align-items-center gap-2">
            <span>{boat.name}</span>
          </div>
        ))}
      </div>
    );
  };
  
  if (!jobs?.length)
    return (
      <div className="card xl:flex xl:justify-content-center">
        <Message
          severity="info"
          message="Please select the jobs you would like to order"
        />
      </div>
    )
  else if (jobs?.length < 2)
    return (
      <div className="card xl:flex xl:justify-content-center">
        <Message
          severity="info"
          message="Please select at least two jobs"
        />
      </div>
    )
  
  return (
    <>
      <Toast ref={toast}/>
      <div id="manage-jobs-order-dialog" className="manage-jobs pt-2">
        
        <div className="card flex justify-content-center">
          {LIFECYCLE ==='prod' ? (
            <Calendar
              id='manage-jobs-date-form-input'
              value={date}
              showIcon
              showButtonBar
              minDate={new Date()}
              onChange={(e) => {
                // @ts-ignore
                setDate(e.value);
              }}
            />
          ): (
            <TextField
              id='manage-jobs-date-form-input'
              type='date'
              value={date?.toISOString() || ''}
              onChange={(e) => {
                setDate(new Date(e.target.value));
              }}
            />
          )}
        </div>
        
        <div className="card flex justify-content-center pt-3">
          <DropdownComponent
            label="truck"
            filterBy="name"
            options={trucks}
            selected={truck}
            setSelected={setTruck}
          />
        </div>
        
        <div className="card flex justify-content-center">
          <OrderList
            dataKey="id"
            className='pt-3'
            value={items}
            onChange={(e) => setItems(e.value)}
            itemTemplate={itemTemplate}
            dragdrop
          />
        </div>
        
        <div className="card flex justify-content-center pt-3">
          <Loader loading={loading}/>
          <Button
            id="jobs-order-save-btn"
            label="Submit"
            icon="pi pi-check"
            className="p-button-text"
            onClick={handleSave}
          />
        </div>
      </div>
    </>
  )
}

export default JobsOrderList;
