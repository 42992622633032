import * as React from 'react';
import { GoogleLogin } from 'react-google-login';
import {GOOGLE_CLIENT_ID} from '../../utils';
import {useAuth} from '../../state-management';


const GoogleLoginButton: React.FC = () => {
  
  const { googleLogin } = useAuth();
  
  const onSuccess = (res: any) => {
    googleLogin(res.tokenId).catch((error) => {
      console.log(error)
    })
  }
  
  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      onSuccess={onSuccess}
      onFailure={(res) => {
        console.log('google login failed:', res)
      }}
      cookiePolicy={'single_host_origin'}
    />
  )
};

export default GoogleLoginButton;
