import * as React from 'react';

import {AiOutlineCar} from 'react-icons/ai';

import {Truck} from '../../state-management/types';
import './styles.scss'

interface TruckComponentProps {
  labels?: boolean
  icons?: boolean
  truck?: Truck
}

const TruckComponent: React.FC<TruckComponentProps> = ({ truck, labels, icons }) => {
  
  const label = labels && (
    <strong className="mr-2">Truck:</strong>
  )
  
  const text = truck?.name || 'Unassigned'
  
  return (
    <div className="truck-component">
      {icons && <AiOutlineCar className='mr-2'/>}
      {label} {text}
    </div>
  )
};

export default TruckComponent;
