import * as React from 'react';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {Answer, Boat, Job, MasterTask, Question, Task} from '../../state-management/types';
import {AnswerComponent, Loader} from '../index';
import {useQuery} from '@apollo/client';
import {GET_JOB} from '../../hooks/useJobs';
import {useEffect} from 'react';

interface JobResultsProps {
  job?: Job
}

const JobResultsComponent: React.FC<JobResultsProps> = ({ job }) => {
  
  const jobRes = useQuery(GET_JOB, {
    skip: !job?.id,
    variables: { id: job?.id },
  })
  
  useEffect(() => {
    if (job?.id)
      jobRes.refetch()
  }, [job?.id]) // eslint-disable-line
  
  if (jobRes.loading)
    return <Loader loading={jobRes.loading}/>
  
  const jobData = jobRes.data?.job
  
  const boatHasTaskWithAnswers = (boat: Boat, tasks: Task[]) => {
    const boatTasks = tasks?.filter((t: Task) => t?.boat?.id === boat?.id)
    return boatTasks?.filter((t: Task) => t?.answers?.length).length
  }
  
  const findAnswer = (masterTask: MasterTask, tasks: Task[], question: Question) => {
    const task = tasks?.find((t: Task) => t?.master_task?.id === masterTask?.id)
    const answer = task?.answers?.find((a: Answer) => a?.question_id === question?.id)
    return <AnswerComponent answer={answer}/>
  }
  
  const numAnswersForMasterTask = (masterTask: MasterTask, tasks: Task[]) => {
    const task = tasks?.find((t: Task) => t?.master_task?.id === masterTask?.id)
    return task?.answers?.length ?? 0
  }
  
  return (
    <Accordion key={job?.id}>
      {jobData?.filtered_boats?.map((boat: Boat) => (
        <AccordionTab key={boat?.id} header={boat?.name}>
          {boatHasTaskWithAnswers(boat, jobData?.tasks) && boat?.boat_type?.master_tasks?.filter((masterTask: MasterTask) => masterTask.questions?.length).map((masterTask: MasterTask) => (
            <Accordion>
              <AccordionTab key={masterTask?.id} header={`${masterTask?.name} (${numAnswersForMasterTask(masterTask, jobData?.tasks)} / ${masterTask?.questions?.length})`}>
                <div key={masterTask?.id} className="card-body">
                  {masterTask?.questions?.map((question: Question) => (
                    <div key={question?.id} className="border-1 surface-border border-round m-2 text-center py-5 px-3">
                      <p>
                        <strong>Question:</strong>
                        <div>{question?.title}</div>
                      </p>
                      <p>
                        {findAnswer(masterTask, jobData?.tasks, question)}
                      </p>
                    </div>
                  ))}
                </div>
              </AccordionTab>
            </Accordion>
          ))}
        </AccordionTab>
      ))}
    </Accordion>
  )
};

export default JobResultsComponent;
