import React, {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';

import {Menubar} from 'primereact/menubar';
import {Toolbar} from 'primereact/toolbar';
import {Menu} from 'primereact/menu';
import {Button} from 'primereact/button';

import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import {Stack} from '@mui/material';

import {FiUser} from 'react-icons/fi';

import {publicBucketUrl} from '../../utils';
import {useAuth} from '../../state-management';
import {DrawerMenuItems, ActiveMarinaDropdown} from '..';

import './styles.scss'


export const drawerWidth = 240;

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { state, getUser, logout } = useAuth();
  const navigate = useNavigate();
  
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  
  const menuRight = useRef(null);
  
  useEffect(() => {
    getUser().catch(({ error }) => {
      console.log('ERROR:', error)
    })
  }, [state.user?.id]) // eslint-disable-line
  
  useEffect(() => {
    const lastPath = sessionStorage.getItem('lastPath')
    if (lastPath)
      navigate(lastPath);
  }, []); // eslint-disable-line
  
  
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };
  
  const handleDrawerToggle = () => {
    if (!isClosing)
      setMobileOpen(!mobileOpen);
  };
  
  const logoDisplayDetails = state.isAuthenticated ? 'none' : 'block';
  
  const toolbarStart = (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          mr: 2,
          display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none'},
        }}
      >
        <MenuIcon />
      </IconButton>
      
      <IconButton
        color="inherit"
        edge="start"
        onClick={() => navigate('/dashboard')}
        sx={{
          mr: 2,
          display: {
            xs: logoDisplayDetails,
            sm: 'block',
            md: 'block',
            lg: 'block',
            xl: 'block',
          },
        }}
      >
        <Stack direction="row" spacing={2}>
          <img
            src={`${publicBucketUrl}/icon.png`}
            alt="Boatload Logo"
            className="layout--logo"
          />
          <Typography
            variant="h4"
            noWrap
            component="div"
            className="mt-1"
          >
            Boatload
          </Typography>
        </Stack>
      </IconButton>
    </>
  );
  
  const toolbarCenter = (
    <>
      {state.isAuthenticated && (
        <div className="layout--active-marina-container">
          <ActiveMarinaDropdown/>
        </div>
      )}
    </>
  );
  
  const activeUserMenu = (
  <>
    <Menu
      id="active-user-menu"
      popup
      popupAlignment="right"
      ref={menuRight}
      model={[
        {
          id: 'active-user-menu-logout',
          label: 'Logout',
          icon: 'pi pi-fw pi-sign-out',
          className: 'ml-auto layout--active-user-menu-item',
          command: async () => {
            await logout();
            navigate('/login');
          },
        },
      ]}
    />
    <Button
      icon={<FiUser className="layout--active-user-menu-btn-icon"/>}
      className="layout--active-user-menu-btn"
      aria-controls="popup_menu_right"
      aria-haspopup
      onClick={(event) => {
        // @ts-ignore
        menuRight?.current?.toggle(event)
      }}
    />
  </>
  );
  
  const activeUserMenubar = (
    <Menubar
      id="active-user-menu"
      menuIcon={<FiUser/>}
      model={[
        // {
        //   id: 'active-user-menu-settings',
        //   label: 'Settings',
        //   icon: 'pi pi-fw pi-cog',
        //   command: async () => {
        //     navigate('/settings');
        //   },
        // },
        {
          id: 'active-user-menu-logout',
          label: 'Logout',
          icon: 'pi pi-fw pi-sign-out',
          className: 'width-100',
          command: async () => {
            await logout();
            navigate('/login');
          },
        },
      ]}
    />
  );
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="default"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar
          className="layout--toolbar"
          start={toolbarStart}
          center={toolbarCenter}
          end={state.isAuthenticated ? activeUserMenubar : null}
        />
      </AppBar>
      
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        onClick={handleDrawerToggle}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <DrawerMenuItems />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          <DrawerMenuItems />
        </Drawer>
      </Box>
      
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: '100vh', // Ensure the main Box takes full height of the viewport
          overflow: 'auto', // Enable scrolling if content overflows
        }}
      >
        <Toolbar />
        <br/>
        <br/>
        {children}
      </Box>
    </Box>
  )
}

export default Layout
