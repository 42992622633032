import { createContext } from 'react'
import { AuthDispatch } from './reducer'
import {
  Marina,
  User,
} from '../types'

export interface AuthState {
  isAuthenticated: boolean
  loading: boolean
  exp: string
  error?: string
  user?: User
  activeMarina?: Marina
}

export interface DecodedToken {
  user_id: number
}

export interface AuthContextState {
  state: AuthState
  dispatch: AuthDispatch
}

export const defaultState = (): AuthState => ({
  user: undefined,
  activeMarina: undefined,
  exp: '',
  error: '',
  loading: false,
  isAuthenticated: false,
})

export const Context = createContext<AuthContextState>({
  state: defaultState(),
  dispatch: () => null,
})
