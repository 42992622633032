import React, {Suspense, lazy} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import { useAuth } from '../state-management'

const Home = lazy(() => import('../pages/home'));
const Terms = lazy(() => import('../pages/terms'));
const Signup = lazy(() => import('../pages/signup'));
const Login = lazy(() => import('../pages/login'));
const Forgot = lazy(() => import('../pages/forgot'));
const Orgs = lazy(() => import('../pages/orgs'));
const Marinas = lazy(() => import('../pages/marinas'));
const Customers = lazy(() => import('../pages/customers'));
const Boats = lazy(() => import('../pages/boats'));
const BoatTypes = lazy(() => import('../pages/boat-types'));
const JobTypes = lazy(() => import('../pages/job-types'));
const Trucks = lazy(() => import('../pages/trucks'));
const Users = lazy(() => import('../pages/users'));
const Jobs = lazy(() => import('../pages/jobs'));
const JobsDashboard = lazy(() => import('../pages/jobs-dashboard'));
const MasterTasks = lazy(() => import('../pages/master-tasks'));
const Questions = lazy(() => import('../pages/questions'));
const TaskList = lazy(() => import('../pages/job-tasks'));


interface RequireAuthProps {
  children: React.ReactNode
  redirectTo: string
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children, redirectTo }) => {
  const {state} = useAuth();
  return state.isAuthenticated ? <>{children}</> : <Navigate to={redirectTo} />;
}

interface PrivateRouteProps {
  element: React.FC
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element: Component }) => {
  return (
    <RequireAuth redirectTo="/login">
      <Component />
    </RequireAuth>
  );
}

const BaseRouter: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path='/login' element={<Login/>} />
        <Route path='/signup' element={<Signup/>} />
        <Route path='/terms' element={<Terms/>} />
        <Route path='/forgot' element={<Forgot/>} />
        <Route path='/home' element={<Home/>} />
        
        <Route path='/marinas' element={<PrivateRoute element={Marinas} />} />
        <Route path='/users' element={<PrivateRoute element={Users} />} />
        <Route path='/trucks' element={<PrivateRoute element={Trucks} />} />
        <Route path='/customers' element={<PrivateRoute element={Customers} />} />
        <Route path='/boats' element={<PrivateRoute element={Boats} />} />
        <Route path='/organizations' element={<PrivateRoute element={Orgs} />} />
        <Route path='/job-types' element={<PrivateRoute element={JobTypes} />} />
        <Route path='/dashboard' element={<PrivateRoute element={JobsDashboard} />} />
        <Route path='/boat-types' element={<PrivateRoute element={BoatTypes} />} />
        <Route path='/questions' element={<PrivateRoute element={Questions} />} />
        <Route path='/master-tasks' element={<PrivateRoute element={MasterTasks} />} />
        <Route path="/jobs/:jobId" element={<PrivateRoute element={TaskList} />} />
        <Route path='/jobs' element={<PrivateRoute element={Jobs} />} />
        
        <Route path='/' element={Home} />
      </Routes>
    </Suspense>
  )
}

export default BaseRouter
