import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useRouteChangeHandler = () => {
  const {pathname} = useLocation()
  
  useEffect(() => {
    const cleanPathname = pathname.trim();
    const shouldSet = cleanPathname !== '/' && cleanPathname !== '/home' && cleanPathname !== '/login' && cleanPathname !== '/signup' && cleanPathname !== '/logout';
    if (shouldSet)
      sessionStorage.setItem('lastPath', cleanPathname)
  }, [pathname])
}
