import React, {
  useReducer,
  Reducer,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { reducer, AuthAction } from './reducer';
import {
  AuthContextState,
  defaultState,
  Context,
  AuthState,
} from './context';

export type AuthProviderI = (props: { children: ReactNode }) => JSX.Element;

export const AuthProvider: AuthProviderI = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<AuthState, AuthAction>>(
    reducer,
    defaultState()
  );

  const [contextValue, setContextValue] = useState<AuthContextState>({
    state,
    dispatch,
  });

  useEffect(() => {
    setContextValue((newContextValue) => ({
      ...newContextValue,
      state,
    }));
  }, [state]);

  return (
    <Context.Provider value={contextValue}>{children}</Context.Provider>
  );
};
