import React, { useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';


interface FileFieldProps {
  setFiles?: any
  onUpload?: any
  fileUrl?: string
  uploadButtonEnabled?: boolean
}

const FileFieldComponent: React.FC<FileFieldProps> = ({ setFiles, onUpload, fileUrl, uploadButtonEnabled }) => {
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  
  const onTemplateSelect = (e: any) => {
    if (!e?.files) return;
    setFiles(e?.files)
    let _totalSize = totalSize;
    for (let file of e?.files) {
      _totalSize += file?.size;
    };

    setTotalSize(_totalSize);
  }
  
  const onTemplateRemove = (file: any, callback: any) => {
    setTotalSize(totalSize - file?.size);
    if (callback) callback();
  }

  const onTemplateClear = () => {
    setTotalSize(0);
  }

  const headerTemplate = (options: any) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    return (
      <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
        {uploadButtonEnabled ? uploadButton : null}
        {chooseButton}
        {cancelButton}
      </div>
    );
  }

  const itemTemplate = (file: any, props: any) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{width: '50%'}}>
          <img alt={file.name} role="presentation" src={file?.objectURL} width={100} />
        </div>
        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
        <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
      </div>
    )
  }

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        {fileUrl ? (
          <img alt="N/A" role="presentation" src={fileUrl} width={150} />
        ) : (
          <>
            <i className="pi pi-image mt-3 p-5" style={{
              'fontSize': '5em',
              borderRadius: '50%',
              backgroundColor: 'var(--surface-b)',
              color: 'var(--surface-d)'
            }}/>
            <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="my-5">Drag and Drop Image Here</span>
          </>
        )}
      </div>
    )
  }
  
  const uploadOptions = {icon: 'pi pi-fw pi-upload', className: 'custom-upload-btn p-button-rounded p-button-outlined'};
  const chooseOptions = {icon: 'pi pi-fw pi-images', className: 'custom-choose-btn p-button-rounded p-button-outlined'};
  const cancelOptions = {icon: 'pi pi-fw pi-times', className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};
  
  return (
    <div>
      {uploadButtonEnabled && <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />}
      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
      <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />
      
      <div className="card">
        <FileUpload
          url={fileUrl}
          ref={fileUploadRef}
          name="uploads[]"
          accept="image/*,video/*"
          maxFileSize={10000000}
          onSelect={onTemplateSelect}
          customUpload
          uploadHandler={onUpload}
          uploadOptions={uploadOptions}
          onError={onTemplateClear}
          onClear={onTemplateClear}
          headerTemplate={headerTemplate}
          itemTemplate={itemTemplate}
          emptyTemplate={emptyTemplate}
          chooseOptions={chooseOptions}
          cancelOptions={cancelOptions}
        />
      </div>
    </div>
  )
}

export default FileFieldComponent
