import { useQuery, useMutation, gql } from '@apollo/client';

export const GET_JOB_TYPES = gql`
    query GetJobTypes {
        job_types {
            id
            name
            master_tasks {
                id
                name
                is_predeparture
                is_postarrival
                questions {
                    id
                    title
                    possible_answers
                }
            }
            questions {
                id
                title
                possible_answers
            }
            created_at
            updated_at
        }
    }
`;


const UPSERT_JOB_TYPE = gql`
    mutation UpsertJobType($input: JobTypeInput!) {
        upsertJobType(input: $input) {
            id
            name
            master_tasks {
                id
                name
                is_predeparture
                is_postarrival
                questions {
                    id
                    title
                    possible_answers
                }
            }
            questions {
                id
                title
                possible_answers
            }
            created_at
            updated_at
        }
    }
`

const DELETE_JOB_TYPE = gql`
    mutation DeleteJobType($id: ID!) {
        deleteJobType(id: $id)
    }
`

export function useJobTypes() {
  const { data, error, loading } = useQuery(GET_JOB_TYPES)
  
  const [upsertJobTypeMutation] = useMutation(UPSERT_JOB_TYPE, { refetchQueries: [GET_JOB_TYPES] })
  const [deleteJobTypeMutation] = useMutation(DELETE_JOB_TYPE, { refetchQueries: [GET_JOB_TYPES] })
  
  const jobTypes = data?.job_types ?? []
  
  const upsertJobType = async (input: any) => {
    const res =  await upsertJobTypeMutation({ variables: { input } })
    return res?.data?.upsertJobType ?? {}
  }
  
  const deleteJobType = async (id: number) => {
    const res = await deleteJobTypeMutation({ variables: { id } })
    return res?.data?.deleteJobType ?? res
  }
  
  return {
    jobTypes,
    upsertJobType,
    deleteJobType,
    error,
    loading
  }
}
