import { useQuery, useMutation, gql } from '@apollo/client';


const GET_TASKS = gql`
    query GetTasks {
        tasks {
            id
            boat {
                id
            }
            job {
                id
            }
            master_task {
                id
            }
            completed_at
        }
    }
`

export const GET_TASK = gql`
    query GetTask($id: ID!) {
        task(id: $id) {
            id
            boat {
                id
            }
            job {
                id
            }
            master_task {
                id
                name
            }
            completed_at
        }
    }
`

const UPSERT_TASK = gql`
    mutation UpsertTask($input: TaskInput!) {
        upsertTask(input: $input) {
            id
            answer_count
            answers {
                id
                answer
                question_id
                question {
                    id
                    title
                    type
                    possible_answers
                }
            }
            master_task {
                id
                name
            }
            completed_at
        }
    }
`

const DELETE_TASK = gql`
    mutation DeleteTask($id: ID!) {
        deleteTask(id: $id)
    }
`

export const GET_JOB_TASKS = gql`
    query getJobTask($id: ID!) {
        job(id: $id) {
            id
            job_type {
                id
                name
            }
            total_master_task_count
            predeparture_master_task_count
            postarrival_master_task_count
            completed_at
            in_progress
            arrived_at_pickup
            return_in_progress
            tasks {
                id
                updated_at
                created_at
                completed_at
                skipped
                in_progress
                answer_count
                answers {
                    id
                    updated_at
                    created_at
                    answer
                    question_id
                    question {
                        id
                        title
                        type
                        possible_answers
                    }
                }
                master_task {
                    id
                    name
                    is_postarrival
                    is_predeparture
                }
            }
            marina {
                id
                name
            }
            customer {
                id
                full_name
                first_name
                last_name
                primary_phone
            }
            driver {
                id
                first_name
                last_name
                phone
            }
            filtered_boats {
                id
                name
                short_description
                boat_type {
                    id
                    master_tasks {
                        id
                        name
                        questions {
                            id
                            title
                            possible_answers
                            type
                        }
                        is_postarrival
                        is_predeparture
                    }
                }
            }
        }
    }
`;


export function useTasks(id?: number) {
  const taskRes = useQuery(GET_TASK, { skip: !id, variables: { id } })
  
  const [upsertTaskMutation] = useMutation(UPSERT_TASK, { refetchQueries: [{ query: GET_TASKS }] })
  const [deleteTaskMutation] = useMutation(DELETE_TASK, { refetchQueries: [{ query: GET_TASKS }] })
  
  const deleteTask = async (id: number) => {
    const res = await deleteTaskMutation({ variables: { id } })
    return res?.data?.deleteTask ?? res
  }
  
  return {
    taskRes: taskRes?.data?.task ?? {},
    upsertTask: upsertTaskMutation,
    deleteTask,
  }
}
