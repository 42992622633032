import * as React from 'react';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Stack} from '@mui/material';
import Divider from '@mui/material/Divider';

import {CiLocationOn} from 'react-icons/ci';

import {Location} from '../../state-management/types';
import {MapDirectionsButton} from '..';
import './styles.scss';

interface TripCardProps {
  title: string
  onConfirmArrival: () => void
  initialLocation?: Location
  destination?: Location
  location?: Location
}

const TripCardComponent: React.FC<TripCardProps> = ({ title, initialLocation, destination, onConfirmArrival }) => {
  
  const footer = (
    <Stack>
      <Stack>
        <MapDirectionsButton
          location={destination}
          severity="success"
        />
      </Stack>
      <br/>
      <Stack>
        <Button
          id="confirm-arrival-btn"
          label="Confirm Arrival"
          severity="info"
          className="p-button-rounded"
          icon="pi pi-question"
          onClick={onConfirmArrival}
        />
      </Stack>
    </Stack>
  )
  
  const dots = (
    <>
      <div className="trip-card--dot">
      .
      </div>
      <div className="trip-card--dot">
      .
      </div>
      <div className="trip-card--dot">
      .
      </div>
      <br/>
    </>
  );
  
  return (
    <div key={destination?.id} className="card flex justify-content-center">
      <Card title={title} footer={footer} className="trip-card--card trip-card--text md:w-25rem">
        
        <Divider color='white'/>
        <br/>
        
        <span className="trip-card--text">
          <CiLocationOn
            className='mr-2'
            color='red'
          />
          {initialLocation?.full_address || initialLocation?.name}
        </span>
        
        {dots}
        
        <span className="trip-card--text">
          <CiLocationOn
            className='mr-2'
            color='red'
          />
          {destination?.full_address || destination?.name}
        </span>
        
      </Card>
    </div>
  );
};

export default TripCardComponent;
