import axios from 'axios';
import { API_URL } from '../utils';

export const api = () => {
  const token = sessionStorage.getItem('token');
  if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  else (delete axios.defaults.headers.common.Authorization)

  return axios.create({ baseURL: API_URL, timeout: 60000 });
};

export default api();
