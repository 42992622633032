import { useQuery, useMutation, gql } from '@apollo/client';
import {useAuth} from '../state-management';


const GET_BOATS = gql`
    query GetBoats {
        boats {
            id
            name
            make
            model
            year
            color
            hull_id
            created_at
            boat_type {
                id
                name
                trailers_per_truck
            }
            marina {
                id
                name
            }
            customer {
                id
                full_name
                first_name
                last_name
            }
        }
    }
`

const UPSERT_BOAT = gql`
    mutation UpsertBoat($input: BoatInput!) {
        upsertBoat(input: $input) {
            id
            name
            short_description
            make
            model
            year
            color
            hull_id
            created_at
            marina {
                id
                name
            }
            customer {
                id
                full_name
                primary_phone
                email
                location {
                    id
                    full_address
                }
            }
            boat_type {
                id
                name
            }
        }
    }
`

const DELETE_BOAT = gql`
    mutation DeleteBoat($id: ID!) {
        deleteBoat(id: $id)
    }
`


export function useBoats() {
  const { state } = useAuth();
  
  const { data, error, loading } = useQuery(GET_BOATS, {
    skip: !Boolean(state.activeMarina?.id),
    variables: {
      params: {
        boat_params: {
          marina_id: state.activeMarina?.id
        }
      }
    },
  })
  
  const [upsertBoatMutation] = useMutation(UPSERT_BOAT, { refetchQueries: [GET_BOATS] })
  const [deleteBoatMutation] = useMutation(DELETE_BOAT, { refetchQueries: [GET_BOATS] })
  
  
  const boats = data?.boats ?? []
  
  const upsertBoat = async (input: any) => {
    const res = await upsertBoatMutation({ variables: { input } })
    return res?.data?.upsertBoat ?? {}
  }
  
  const deleteBoat = async (id: number) => {
    const res = await deleteBoatMutation({ variables: { id } })
    return res?.data?.deleteBoat ?? res
  }
  
  return {
    boats,
    upsertBoat,
    deleteBoat,
    error,
    loading
  }
}
