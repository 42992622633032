import React, {
  useReducer,
  Reducer,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { reducer, JobAction } from './reducer';
import {
  JobContextState,
  defaultState,
  Context,
  JobState,
} from './context';

export type JobProviderI = (props: { children: ReactNode }) => JSX.Element;

export const JobProvider: JobProviderI = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<JobState, JobAction>>(
    reducer,
    defaultState()
  );

  const [contextValue, setContextValue] = useState<JobContextState>({
    state,
    dispatch,
  });

  useEffect(() => {
    setContextValue((newContextValue) => ({
      ...newContextValue,
      state,
    }));
  }, [state]);

  return (
    <Context.Provider value={contextValue}>{children}</Context.Provider>
  );
};
