import React, { useState, useEffect } from 'react';
import { FaWifi } from 'react-icons/fa';
import { PiWifiSlash } from 'react-icons/pi';

const NetworkStatus: React.FC = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  
  useEffect(() => {
    const updateNetworkStatus = () => {
      setIsOnline(navigator.onLine);
    };
    
    updateNetworkStatus();
    
    const intervalId = setInterval(() => {
      updateNetworkStatus();
    }, 30000);
    
    window.addEventListener('online', updateNetworkStatus);
    window.addEventListener('offline', updateNetworkStatus);
    
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('online', updateNetworkStatus);
      window.removeEventListener('offline', updateNetworkStatus);
    };
  }, []);
  
  return (
    <div>
      {isOnline ? (
        <FaWifi style={{ color: 'green' }} size={20} />
      ) : (
        <PiWifiSlash style={{ color: 'red' }} size={20} />
      )}
    </div>
  );
};

export default NetworkStatus;
