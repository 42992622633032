import * as React from 'react';
import {Link} from 'react-router-dom';

import {IoMdBusiness} from 'react-icons/io';
import {CiDeliveryTruck} from 'react-icons/ci';
import {TbSpeedboat} from 'react-icons/tb';
import {BsFillPersonVcardFill} from 'react-icons/bs';
import {MdOutlineCategory} from 'react-icons/md';
import {RiRouteFill} from 'react-icons/ri';
import {GiOrganigram} from 'react-icons/gi';
import {GrUserWorker} from 'react-icons/gr';
import {FaTasks} from 'react-icons/fa';
import {FiUserPlus} from 'react-icons/fi';
import {AiOutlineHome} from 'react-icons/ai';
import {BsQuestionDiamond} from 'react-icons/bs';
import {HiLogin} from 'react-icons/hi';
import {BiTask} from 'react-icons/bi';

import {useAuth} from '../../state-management';
import {shouldRenderListGroup, shouldRenderMenuItem} from './filter';
import './styles.scss';


export interface MenuItemProps {
  id?: string;
  to?: string;
  display?: string;
  roleKeys?: string[]
  permissionsKey?: string
  onClick?: () => void;
  icon?: any;
}

export interface MenuItemGroup {
  label?: string;
  icon?: any
  subItems?: MenuItemProps[]
}

const CustomMenuItem: React.FC<MenuItemProps> = ({
  id,
  to,
  display,
  icon: Icon
}) => {
  
  const menuI = (
    <div id={id} className="menu-item--sub-item">
      <div className="menu-item--sub-item-icon">
        {Icon ? <Icon /> : null}
      </div>
      
      <div className="menu-item--sub-item-text">
        {display}
      </div>
    </div>
  );
  
  if (to)
    return (
      <Link className="menu-item--sub-item-link" to={to}>
        {menuI}
      </Link>
    );
  
  return menuI;
};


const DrawerMenuItems: React.FC = () => {
  
  const { state } = useAuth();
  
  const getMenuItems = () => {
    const authenticatedMenuItemGroups: MenuItemGroup[] = [
      {
        subItems: [
          {
            display: 'Dashboard',
            icon: AiOutlineHome
          }
        ]
      },
      {
        label: 'Admin',
        subItems: [
          {
            display: 'Organizations',
            roleKeys: ['superadmin'],
            permissionsKey: 'Org',
            icon: GiOrganigram
          },
          {
            display: 'Marinas',
            roleKeys: ['admin'],
            permissionsKey: 'Marina',
            icon: IoMdBusiness
          },
        ]
      },
      {
        label: 'Management',
        subItems: [
          {
            display: 'Jobs',
            roleKeys: ['admin', 'manager', 'technician'],
            permissionsKey: 'Job',
            icon: BiTask
          },
          {
            display: 'Customers',
            roleKeys: ['admin', 'manager'],
            permissionsKey: 'Customer',
            icon: BsFillPersonVcardFill
          },
          {
            display: 'Boats',
            roleKeys: ['admin', 'manager', 'technician'],
            permissionsKey: 'Boat',
            icon: TbSpeedboat
          },
          {
            display: 'Users',
            permissionsKey: 'User',
            icon: GrUserWorker
          },
          {
            display: 'Trucks',
            roleKeys: ['admin', 'manager'],
            permissionsKey: 'Truck',
            icon: CiDeliveryTruck
          },
        ]
      },
      {
        label: 'Jobs Outline',
        subItems: [
          {
            display: 'Boat Types',
            roleKeys: [],
            permissionsKey: 'BoatType',
            icon: MdOutlineCategory
          },
          {
            display: 'Job Types',
            roleKeys: [],
            permissionsKey: 'JobType',
            icon: RiRouteFill
          },
          {
            display: 'Master Tasks',
            roleKeys: [],
            permissionsKey: 'MasterTask',
            icon: FaTasks
          },
          {
            display: 'Questions',
            roleKeys: [],
            permissionsKey: 'Question',
            icon: BsQuestionDiamond
          },
        ]
      },
    ]
    
    const unauthenticatedMenuItemGroups = [
      {
        label: 'Signup',
        icon: FiUserPlus
      },
      {
        label: 'Login',
        icon: HiLogin
      }
    ]
    
    return state.isAuthenticated ? authenticatedMenuItemGroups : unauthenticatedMenuItemGroups;
  }
  
  const formatMenuItemId = (config: MenuItemProps) => {
    return config?.display?.toLowerCase().replace(' ', '-');
  }
  
  const renderMenuItemList = (items: MenuItemProps[]) => {
    if (!shouldRenderListGroup(items, state.user)) return null;
    
    return items?.map((item: MenuItemProps) => (
      <div key={item.id || item.display}>
        {shouldRenderMenuItem(item, state.user) && (
          <div className="menu-item-container">
            <CustomMenuItem
              id={`drawer-menu-btn-${formatMenuItemId(item)}`}
              icon={item?.icon}
              to={`/${formatMenuItemId(item)}`}
              display={item?.display}
            />
          </div>
        )}
      </div>
    ));
  }
  
  const renderList = () => {
    const items = getMenuItems();
    
    return (
      <div className="menu-list">
        {items?.map((config: MenuItemGroup, i: number) => (
          <div key={config.label || i}>
            {shouldRenderListGroup(config.subItems, state.user) && (
              <div className="menu-item-group">
                {config.subItems?.length ? (
                  <div>
                    <div className="menu-item--group-header">
                      {config.label}
                    </div>
                    {renderMenuItemList(config.subItems)}
                  </div>
                ): renderMenuItemList([{display: config.label, icon: config.icon}])}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
  
  return (
    <>
      {renderList()}
    </>
  );
}

export default DrawerMenuItems;
