import * as React from 'react';

import {BsFillCalendar2CheckFill, BsFillCalendarXFill} from 'react-icons/bs';
import {CiClock2} from 'react-icons/ci';
import {TbClockOff} from 'react-icons/tb';

import {formatDate, formatLocalTime} from '../../utils';
import './styles.scss'

interface DateTimeComponentProps {
  labels?: boolean
  icons?: boolean
  showDate?: boolean
  date?: string
  showTime?: boolean
  time?: string
  dateTime?: string
}

const DateTimeComponent: React.FC<DateTimeComponentProps> = ({ showDate = true, showTime = true, date, time, dateTime, labels, icons }) => {
  
  if (dateTime) {
    date = dateTime
    time = dateTime
  }
  
  const label = (text: string) => {
    if (!labels) return
    return (
      <strong className="datetime-label mr-2">
        {text}:
      </strong>
    )
  }
  
  const dateLabel = label('Date')
  const timeLabel = label('Time')
  
  const dateComponent = (
    <div className="date-component">
      {date ? (
        <>
          {icons && <BsFillCalendar2CheckFill className='mr-2'/>}
          {dateLabel}
          <>{formatDate(date)}</>
        </>
      ) : (
        <>
          {icons && <BsFillCalendarXFill className='mr-2'/>}
          {dateLabel}
          <>N/A</>
        </>
      )}
    </div>
  )
  
  const timeComponent = (
    <div className="time-component">
      {time ? (
        <>
          {icons && <CiClock2 className='mr-2'/>}
          {timeLabel}
          <>{formatLocalTime(time)}</>
        </>
      ) : (
        <>
          {icons && <TbClockOff className='mr-2'/>}
          {timeLabel}
          <>N/A</>
        </>
      )}
    </div>
  )
  
  return (
    <p>
      {showDate && dateComponent}
      {showTime && timeComponent}
    </p>
  )
};

export default DateTimeComponent;
