import { useQuery, useMutation, gql } from '@apollo/client';


export const GET_MASTER_TASKS = gql`
    query GetMasterTasks {
        master_tasks {
            id
            name
            priority
            enabled
            is_predeparture
            is_postarrival
            questions {
                id
                title
                type
                possible_answers
            }
            job_types {
                id
                name
            }
            boat_types {
                id
                name
            }
            created_at
            updated_at
        }
    }
`


const UPSERT_MASTER_TASK = gql`
    mutation UpsertMasterTask($input: MasterTaskInput!) {
        upsertMasterTask(input: $input) {
            id
            name
            priority
            enabled
            is_predeparture
            is_postarrival
            questions {
                id
                title
                type
                possible_answers
            }
            job_types {
                id
                name
            }
            boat_types {
                id
                name
            }
            created_at
            updated_at
        }
    }
`

const DELETE_MASTER_TASK = gql`
    mutation DeleteMasterTask($id: ID!) {
        deleteMasterTask(id: $id)
    }
`


export function useMasterTasks() {
  const { data, error, loading } = useQuery(GET_MASTER_TASKS)
  
  const [upsertMasterTaskMutation] = useMutation(UPSERT_MASTER_TASK, { refetchQueries: [GET_MASTER_TASKS] })
  const [deleteMasterTaskMutation] = useMutation(DELETE_MASTER_TASK, { refetchQueries: [GET_MASTER_TASKS] })
  
  const masterTasks = data?.master_tasks ?? []
  
  const upsertMasterTask = async (input: any) => {
    const res = await upsertMasterTaskMutation({ variables: { input } })
    return res?.data?.upsertMasterTask ?? {}
  }
  
  const deleteMasterTask = async (id: number) => {
    const res = await deleteMasterTaskMutation({ variables: { id } })
    return res?.data?.deleteMasterTask ?? res
  }
  
  return {
    masterTasks,
    upsertMasterTask,
    deleteMasterTask,
    error,
    loading
  }
}
