import React, { createContext, useContext, useRef } from 'react';
import { Toast } from 'primereact/toast';

const ToastContext = createContext<any>(null);

export const ToastProvider: React.FC = ({ children }) => {
  const toastRef = useRef<any>(null);
  
  const showToast = (severity: string, summary: string, detail: string, life: number = 3000) => {
    toastRef.current?.show({ severity, summary, detail, life });
  };
  
  return (
    <ToastContext.Provider value={showToast}>
      <Toast ref={toastRef} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
