import React from 'react';

import './style.scss'


export default function Footer() {
  return (
    <footer>
      <div>
        Thanks for using Boatload!
      </div>
    </footer>
  );
}
