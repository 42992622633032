import React, {useState} from 'react';

import {Button} from 'primereact/button';
import {RadioButton} from 'primereact/radiobutton';

import {
  Answer,
  Question,
} from '../../../state-management/types';

import './styles.scss';

interface BooleanQuestionComponentProps {
  question: Question
  answer?: Answer
  onSelect?: (questionId: number, selectedAnswer: string, answer?: Answer, image?: File) => void
}

const BooleanQuestionComponent: React.FC<BooleanQuestionComponentProps> = ({
  question,
  answer,
  onSelect,
})=> {
  
  const [selectedAnswer, setSelectedAnswer] = useState<string>(answer?.answer ?? '')
  
  return (
    <>
      <div className="question-radio-option flex align-items-center">
        <RadioButton
          data-cy="radio-option-1"
          inputId="yes"
          name="possible-answer"
          value="Yes"
          disabled={false}
          onChange={(e) => {
            setSelectedAnswer(e.value);
            if (question?.id)
              onSelect?.(question?.id, e.value, answer);
          }}
          checked={selectedAnswer === 'Yes'}
        />
        <label htmlFor="yes" className="ml-2">Yes</label>
      </div>
      <div className="question-radio-option flex align-items-center">
        <RadioButton
          data-cy="radio-option-2"
          inputId="no"
          name="possible-answer"
          value="No"
          disabled={false}
          onChange={(e) => setSelectedAnswer(e.value)}
          checked={selectedAnswer === 'No'}
        />
        <label htmlFor="no" className="ml-2">No</label>
      </div>
    </>
  )
};

export default BooleanQuestionComponent;
