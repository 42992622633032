import React, {useEffect} from 'react';
import {Toolbar} from 'primereact/toolbar';
import {Button} from 'primereact/button';

import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import {Stack} from '@mui/material';

import {FaRegCheckCircle} from 'react-icons/fa';
import {FaCircleExclamation} from 'react-icons/fa6';
import {ImWarning} from 'react-icons/im';

import {
  Answer,
  MasterTask,
  Question,
  Task,
} from '../../state-management/types';
import {useJob} from '../../state-management';
import {JobPhase} from '../../state-management/job/context';
import {
  QuestionComponent,
} from '..';
import './styles.scss';


interface TaskProps {
  phase: JobPhase
}

const TasksComponent: React.FC<TaskProps> = ({ phase }) => {
  
  const {
    state,
    startTask,
    storeAnswer,
    submitTask,
    taskKey,
  } = useJob()
  
  useEffect(() => {
    if (!state.currentTask && phase?.masterTasks[phase?.currentMasterTaskIndex]?.id)
      startTask(phase?.masterTasks[phase?.currentMasterTaskIndex]).catch((e: any) => console.error(e))
  }, [phase?.masterTasks, phase?.currentMasterTaskIndex]); // eslint-disable-line
  
  useEffect(() => {
  }, [state.storedTasks, state.job?.tasks]);
  
  const progress = () => {
    let task = state.job?.tasks?.find((task: Task) => {
      return task?.master_task?.id === phase?.masterTasks[phase.currentMasterTaskIndex]?.id
    })
    
    if (!task?.id)
      return
    
    const numQuestions = phase?.masterTasks[phase.currentMasterTaskIndex]?.questions?.length ?? 0
    const savedAnswers = task?.answers
    
    let uniqueAnswers = new Set()
    let numAnswers = 0
    if (savedAnswers) {
      savedAnswers?.forEach((answer: Answer) => {
        if (answer?.answer)
          uniqueAnswers.add(answer?.question_id)
      })
      numAnswers = uniqueAnswers.size
    }
    
    const storedAnswers = state.storedTasks?.get(taskKey())
    if (storedAnswers) {
      storedAnswers.forEach((answer: Answer) => {
        uniqueAnswers.add(answer?.question_id)
      })
      numAnswers = uniqueAnswers.size
    }
    
    return (
      <>
        <span className="task--question-ratio">
          {numAnswers ??  0} / {numQuestions}
        </span>
        {state.currentTask?.id && state.storedTasks?.get(taskKey()) ? (
          <ImWarning color='orange'/>
        ) : (
          <>
            {state.currentTask?.completed_at ? (
              <FaRegCheckCircle color='limegreen'/>
            ) : (
              <FaCircleExclamation color='red'/>
            )}
          </>
        )}
      </>
    );
  }
  
  const taskName = (
    <div className="task--text">
      <Stack direction="row" spacing={2}>
        <h3>{phase?.masterTasks[phase.currentMasterTaskIndex]?.name}</h3>
      </Stack>
    </div>
  );
  
  const submitTemplate = (
    <div className="task--text">
      <Button
        id="submit-task-btn"
        severity="warning"
        icon="pi pi-send"
        onClick={submitTask}
      />
    </div>
  );
  
  const findAnswer = (question: Question) => {
    return state.currentTask?.answers?.find((a: Answer) => a?.question_id === question?.id)
  }
  
  return (
    <div className="task--container">
      <AppBar
        position="sticky"
        color="default"
        sx={{
          position: 'sticky',
          top: '142px',
        }}
      >
        <Toolbar
          className="task--toolbar"
          start={progress()}
          center={taskName}
          end={submitTemplate}
        />
      </AppBar>
      {phase?.masterTasks[phase.currentMasterTaskIndex]?.questions?.map((question: Question, index) => (
        <div key={question?.id} className="task--question-container">
          <QuestionComponent
            index={index+1}
            question={question}
            answer={findAnswer(question)}
            hideButton={true}
            onSelect={storeAnswer}
          />
          <Divider/>
        </div>
      ))}
      <br/>
    </div>
  );
}

export default TasksComponent
