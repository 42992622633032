import * as React from 'react';
import './styles.scss';

interface PhoneNumberProps {
  phoneNumber: string
  children?: React.ReactNode
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({ phoneNumber, children }) => {
  const cleanNumber = phoneNumber?.replace(/[^0-9]/g, '')
  return (
    <a className="phone-number" href={`tel:+1${cleanNumber}`}>
      {children ?? phoneNumber}
    </a>
  )
}

export default PhoneNumber;
