import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { PrimeReactProvider } from 'primereact/api';
import { ApolloProvider } from '@apollo/client'
import App from './App'
import {
  AuthProvider,
  JobProvider,
} from './state-management'
import reportWebVitals from './reportWebVitals'
import client from './state-management/apollo'
import {ToastProvider} from './providers/toast';


const composeProviders = (...Providers: any[]) => (Child: any) => (
  props: any
): JSX.Element =>
  Providers.reduce(
    (acc, Provider) => <Provider>{acc}</Provider>,
    <Child {...props} />
  )

const WrappedApp = composeProviders(
  Router,
  PrimeReactProvider,
  AuthProvider,
  JobProvider,
  ToastProvider,
)(App)

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <WrappedApp />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
