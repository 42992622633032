import * as React from 'react';
import { TextField } from '@material-ui/core';
import './styles.scss';

interface TextFieldProps {
  value: string;
  onChange: (e: React.ChangeEvent<{ value: string }>) => void;
  id: string;
  helperText?: string;
  label?: string;
  type?: string;
  autoComplete?: string;
  shrinkLabel?: boolean;
  handleSubmit?: any;
}

const TextFieldComponent: React.FC<TextFieldProps> = ({
  onChange,
  value,
  id,
  helperText,
  label,
  type = 'text',
  autoComplete = 'text',
  shrinkLabel,
}) => {
  return (
    <TextField
      className='text-field--text'
      color='primary'
      inputProps={{ type, autoComplete }}
      InputProps={{
        className: 'text-field--text-input',
        classes: {
          notchedOutline: 'text-field--input-outline',
          focused: 'text-field--input-focused',
          input: 'text-field--input',
        },
      }}
      InputLabelProps={{
        shrink: shrinkLabel,
        className: 'text-field--text-label',
        classes: {
          outlined: 'text-field--text-label-outlined',
          focused: 'text-field--label-focused',
        },
      }}
      id={id}
      fullWidth
      label={label}
      onChange={onChange}
      value={value}
      helperText={helperText}
      variant='outlined'
      // onKeyPress={(e) => {
      //   if (e.key === 'Enter') handleSubmit(e);
      // }}
    />
  );
};

export default TextFieldComponent;
