import * as React from 'react';
import {useQuery} from '@apollo/client';

import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import Divider from '@mui/material/Divider';

import {
  Answer,
  Question,
} from '../../state-management/types';
import {useJob} from '../../state-management';
import {GET_ANSWER} from '../../hooks/useAnswers';

import FileField from '../file-field';
import {
  BooleanQuestionComponent,
  Loader,
  MapDirectionsButton,
  MultipleChoiceQuestionComponent,
  TextQuestionComponent, TripQuestionComponent,
} from '..';
import './styles.scss';

interface QuestionComponentProps {
  question: Question
  answer?: Answer
  onSelect?: (questionId: number, selectedAnswer: string, answer?: Answer, image?: File) => void
  onSave?: (question: Question, selectedAnswer: string, answer?: Answer, image?: File) => Promise<any>
  index?: number
  readOnly?: boolean
  hideButton?: boolean
}

const QuestionComponent: React.FC<QuestionComponentProps> = ({
  index,
  question,
  onSelect,
  onSave,
  answer,
})=> {
  const toast = React.useRef(null);
  
  const [selectedAnswer, setSelectedAnswer] = React.useState<string>(answer?.answer ?? '')
  const [image, setImage] = React.useState<File>()
  const [signedUrl, setSignedUrl] = React.useState<string>(answer?.signed_url ?? '')
  const [loading, setLoading] = React.useState(false)
  
  const {state} = useJob()
  
  const answerRes = useQuery(GET_ANSWER, {
    variables: { id: answer?.id },
    skip: !answer?.id,
    onCompleted: (data) => {
      if (data?.answer?.signed_url) setSignedUrl(data?.answer?.signed_url)
      if (data?.answer?.answer) setSelectedAnswer(data?.answer?.answer)
    },
    onError: (err) => {
      // @ts-ignore
      toast.current?.show({severity: 'error', summary: 'Error', detail: 'Retrieving Answer', life: 3000});
    }
  })
  
  const onFileSelect = (files: any) => {
    if (files?.length) {
      setImage(files[0])
      if (onSelect && question?.id)
        onSelect?.(question?.id, selectedAnswer, answer, files[0])
    }
  }
  
  const onSubmit = async () => {
    setLoading(true)
    if (onSave)
      await onSave(question, selectedAnswer, answer, image)
    setLoading(false)
  }
  
  const questionTypesMap: any = {
    'TEXT': (
      <TextQuestionComponent
        question={question}
        answer={answer}
        onSelect={onSelect}
      />
    ),
    'BOOLEAN': (
      <BooleanQuestionComponent
        question={question}
        answer={answer}
        onSelect={onSelect}
      />
    ),
    'MULTIPLE_CHOICE': (
      <MultipleChoiceQuestionComponent
        question={question}
        answer={answer}
        onSelect={onSelect}
      />
    ),
    'PHOTO': (
      <FileField
        fileUrl={signedUrl}
        onUpload={onSubmit}
        setFiles={onFileSelect}
      />
    ),
    'VIDEO': (
      <FileField
        uploadButtonEnabled={false}
        fileUrl={signedUrl}
        setFiles={onFileSelect}
      />
    ),
    'TRIP': (
      <TripQuestionComponent
        location={state.job?.customer?.location}
        question={question}
        answer={answer}
        onSelect={onSelect}
      />
    )
  }
  
  const renderQuestion = (question: Question) => {
    if (question?.type) {
      return questionTypesMap[question?.type]
    } else {
      return (
        <div className="flex flex-column gap-3">
          <h4>Question Type Not Supported</h4>
          <p>{question?.type} is not supported</p>
        </div>
      )
    }
  }
  
  return (
    <div id="question-component" key={question?.id} className="question-card-body">
      <Toast ref={toast}/>
      <h4 className="question-title">
        {index ? `${index}. ` : null} {question?.title}
        <Loader loading={answerRes.loading || loading} />
      </h4>
      
      <Divider/>
      <br/>
      
      <div className="card flex">
        <div className="flex flex-column gap-3">
          {renderQuestion(question)}
        </div>
      </div>
    </div>
  )
};

export default QuestionComponent;
