import { useQuery, useMutation, gql } from '@apollo/client';
import {GET_MASTER_TASKS} from './useMasterTasks';
import {GET_JOB_TYPES} from './useJobTypes';

const GET_QUESTION_TYPES = gql`
    query GetQuestionTypes {
        questionTypes
    }
`

const GET_QUESTIONS = gql`
    query GetQuestions {
        questions {
            id
            title
            priority
            enabled
            possible_answers
            type
            master_tasks {
                id
                name
                is_predeparture
                is_postarrival
            }
            boat_types {
                id
                name
            }
            job_types {
                id
                name
            }
            
            created_at
            updated_at
        }
    }
`;

const UPSERT_QUESTION = gql`
    mutation UpsertQuestion($input: QuestionInput!) {
        upsertQuestion(input: $input) {
            id
            title
            priority
            enabled
            possible_answers
            type
            master_tasks {
                id
                name
                is_predeparture
                is_postarrival
            }
            job_types {
                id
                name
            }
            boat_types {
                id
                name
            }
            
            updated_at
            created_at
        }
    }
`

const DELETE_QUESTION = gql`
    mutation DeleteQuestion($id: ID!) {
        deleteQuestion(id: $id)
    }
`

export function useQuestions() {
  const { data, error, loading } = useQuery(GET_QUESTIONS);
  const questionTypesRes = useQuery(GET_QUESTION_TYPES);
  
  const refetchQueries = [
    GET_QUESTIONS,
    GET_MASTER_TASKS,
    GET_JOB_TYPES,
  ]
  
  const [upsertQuestionMutation] = useMutation(UPSERT_QUESTION, { refetchQueries })
  const [deleteQuestionMutation] = useMutation(DELETE_QUESTION, { refetchQueries })
  
  const questions = data?.questions ?? []
  
  return {
    questionTypes: questionTypesRes.data?.questionTypes ?? [],
    questions,
    upsertQuestion: upsertQuestionMutation,
    deleteQuestion: deleteQuestionMutation,
    error,
    loading
  }
}
