import React from 'react';
import {Button} from 'primereact/button';

import {Location} from '../../state-management/types';

interface MapDirectionsButtonProps {
  location?: Location
  severity?: 'success' | 'info' | 'warning' | 'danger'
}

const MapDirectionsButton: React.FC<MapDirectionsButtonProps> = ({ location, severity }) => {
  
  const destinationString = () => {
    if (location?.latitude && location?.longitude)
      return `${location?.latitude},${location?.longitude}`
    
    return location?.full_address || location?.name || ''
  }
  
  const handleDirectionsClick = () => {
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(destinationString())}`;
    window.open(mapsUrl, '_blank');
  };
  
  return (
    <Button
      id="open-maps-btn"
      label="Open in Maps"
      icon="pi pi-external-link"
      className={`p-button-rounded p-button-${severity || 'info'}`}
      onClick={handleDirectionsClick}
    />
  );
};

export default MapDirectionsButton;
