import * as React from 'react';
import {
  Answer,
} from '../../state-management/types';
import {GET_ANSWER} from '../../hooks/useAnswers';
import {useQuery} from '@apollo/client';
import {Loader} from '../index';

interface AnswerComponentProps {
  answer?: Answer
}

const AnswerComponent: React.FC<AnswerComponentProps> = ({ answer }) => {
  const res = useQuery(GET_ANSWER, { variables: { id: answer?.id }, skip: !answer?.id })
  
  const imageRef = React.useRef(null);
  
  const handleImageClick = () => {
    const elem = imageRef.current as any;
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen().catch((err: any) => {
          console.error('Failed to enter fullscreen mode:', err);
        });
      } else if (elem.mozRequestFullScreen) { // Firefox
        elem.mozRequestFullScreen().catch((err: any) => {
          console.error('Failed to enter fullscreen mode:', err);
        });
      } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
        elem.webkitRequestFullscreen().catch((err: any) => {
          console.error('Failed to enter fullscreen mode:', err);
        });
      } else if (elem.msRequestFullscreen) { // IE/Edge
        elem.msRequestFullscreen().catch((err: any) => {
          console.error('Failed to enter fullscreen mode:', err);
        });
      } else {
        console.error('Fullscreen API is not supported.');
      };
    };
  };
  
  if (res.loading) return <Loader loading={res.loading}/>
  
  if (!answer?.answer) return (
    <strong>No answer</strong>
  )
  
  return (
    <div key={answer?.id}>
      {res.data?.answer?.signed_url ? (
        <img
          key={answer?.id}
          className="card-img-top"
          src={res.data?.answer?.signed_url}
          alt="N/A"
          ref={imageRef}
          style={{ cursor: 'pointer', maxWidth: '40%' }}
          onClick={handleImageClick}
        />
      ) : (
        <>
          <strong>Answer</strong>: {res.data?.answer?.answer}
        </>
      )}
    </div>
  )
};

export default AnswerComponent;
