import { FC, useState, useEffect } from 'react';
import {GMapify} from 'g-mapify';
import  'g-mapify/dist/index.css';

import {GOOGLE_MAPS_KEY} from '../../utils';
import useGeocode from '../../state-management/geocode';
import {Location} from '../../state-management/types';
import {Loader, Message, MapDirectionsButton} from '..';

import './styles.scss';


interface MapProps {
  label?: string,
  openMapBtn?: boolean,
  location?: Location,
}

const Map: FC<MapProps> = ({ label, openMapBtn, location }) => {
  const [geoRes, setGeoRes] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  
  const { getGeocode } = useGeocode()
  
  const onMapSelect = (status: any, res: any) => {
    console.warn(status, res);
  }

  useEffect(() => {
    if (location?.latitude && location?.longitude)
      setGeoRes({ lat: location?.latitude, lng: location?.longitude })
    else if (location?.full_address) {
      setLoading(true)
      getGeocode(location.full_address)
        .then((location: any) => {
          setLoading(false)
          setGeoRes(location)
        })
        .catch((err: any) => setError(err))
    } else {
      setError('No location provided')
    }
  }, [location]) // eslint-disable-line
  
  const locationName = location?.name || location?.full_address || location?.address
  const displayLabel = label ? `${label} - ${locationName}` : locationName

  return (
    <>
      {displayLabel && <strong>{displayLabel}</strong>}
      {geoRes && (
        <GMapify
          appKey={GOOGLE_MAPS_KEY}
          lat={geoRes.lat}
          lng={geoRes.lng}
          onSelect={onMapSelect}
        />
      )}
      {openMapBtn && !loading && (
        <MapDirectionsButton location={location} />
      )}
      <Loader loading={loading}/>
      <Message message={error} severity="error" />
      <br/>
    </>
  );
};

export default Map;

