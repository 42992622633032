import React from 'react';
import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';
import Divider from '@mui/material/Divider';
import {Skip} from '../../state-management/types';
import {formatDate, formatLocalTime} from '../../utils';

interface SkipsListProps {
  skips?: Skip[];
}

const SkipsList: React.FC<SkipsListProps> = ({skips }) => {
  
  const itemTemplate = (item: Skip, index: number) => {
    return (
      <div className="col-12" key={item.skipped_at}>
        <div className={classNames('flex flex-column xl:flex-row xl:align-items-start p-4 gap-4', { 'border-top-1 surface-border': index !== 0 })}>
          <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
            <Tag value={item.reason} severity={'info'}/>
            <span className="font-semibold">{formatLocalTime(item.skipped_at)} {formatDate(item.skipped_at)}</span>
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <div className="card">
      <div className="grid grid-nogutter">
      {skips?.map((skip, i) => (
        <div>
          {itemTemplate(skip, i)}
        </div>
      ))}
      </div>
    </div>
  )
}

export default SkipsList;
