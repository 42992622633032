import { useQuery, useMutation, gql } from '@apollo/client';

const GET_BOAT_TYPES = gql`
    query GetBoatTypes {
        boat_types {
            id
            name
            trailers_per_truck
            master_tasks {
                id
                name
                questions {
                    id
                    title
                    possible_answers
                }
            }
            questions {
                id
                title
                possible_answers
            }
            created_at
            updated_at
        }
    }
`;


const UPSERT_BOAT_TYPE = gql`
    mutation UpsertBoatType($input: BoatTypeInput!) {
        upsertBoatType(input: $input) {
            id
            name
            trailers_per_truck
            master_tasks {
                id
                name
                questions {
                    id
                    title
                    possible_answers
                }
            }
            questions {
                id
                title
                possible_answers
            }
            created_at
            updated_at
        }
    }
`

const DELETE_BOAT_TYPE = gql`
    mutation DeleteBoatType($id: ID!) {
        deleteBoatType(id: $id)
    }
`


export function useBoatTypes() {
  const { data, error, loading } = useQuery(GET_BOAT_TYPES)
  
  const [upsertBoatTypeMutation] = useMutation(UPSERT_BOAT_TYPE, { refetchQueries: [GET_BOAT_TYPES] })
  const [deleteBoatTypeMutation] = useMutation(DELETE_BOAT_TYPE, { refetchQueries: [GET_BOAT_TYPES] })
  
  const boatTypes = data?.boat_types ?? []
  
  return {
    boatTypes,
    upsertBoatType: upsertBoatTypeMutation,
    deleteBoatType: deleteBoatTypeMutation,
    error,
    loading
  }
}
