import * as React from 'react';

import {useMarinas} from '../../hooks/useMarinas';
import {useAuth} from '../../state-management';
import {DropdownComponent} from '..';
import './styles.scss';


export default function ActiveMarinaDropdown() {

  const { state, setActiveMarina } = useAuth();
  const { marinas } = useMarinas();
  
  if (!marinas?.length || marinas?.length <= 1) return null;

  return (
    <DropdownComponent
      label="active-marina"
      filterBy="name"
      showClear={false}
      options={marinas}
      selected={state.activeMarina}
      setSelected={setActiveMarina}
    />
  );
}
