import { useQuery, useMutation, gql } from '@apollo/client';
import {useAuth} from '../state-management';

const GET_TRUCKS = gql`
    query GetTrucks {
        trucks {
            id
            name
            license_plate
            marina {
                id
                name
            }
            created_at
            updated_at
        }
    }
`;



const UPSERT_TRUCK = gql`
    mutation UpsertTruck($input: TruckInput!) {
        upsertTruck(input: $input) {
            id
            name
            license_plate
            marina {
                id
                name
            }
            created_at
            updated_at
        }
    }
`

const DELETE_TRUCK = gql`
    mutation DeleteTruck($id: ID!) {
        deleteTruck(id: $id)
    }
`


export function useTrucks() {
  const { state } = useAuth();
  
  const { data, error, loading } = useQuery(GET_TRUCKS, {
    skip: !Boolean(state.activeMarina?.id),
    variables: {
      params: {
        boat_params: {
          marina_id: state.activeMarina?.id
        }
      }
    }
  })
  
  const [upsertTruckMutation] = useMutation(UPSERT_TRUCK, { refetchQueries: [GET_TRUCKS] })
  const [deleteTruckMutation] = useMutation(DELETE_TRUCK, { refetchQueries: [GET_TRUCKS] })
  
  const trucks = data?.trucks ?? []
  
  const upsertTruck = async (input: any) => {
    const res = await upsertTruckMutation({ variables: { input } })
    return res?.data?.upsertTruck ?? {}
  }
  
  const deleteTruck = async (id: number) => {
    const res = await deleteTruckMutation({ variables: { id } })
    return res?.data?.deleteTruck ?? res
  }
  
  return {
    trucks,
    upsertTruck,
    deleteTruck,
    error,
    loading
  }
}
