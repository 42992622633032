import { useQuery, useMutation, gql } from '@apollo/client';

export const GET_USER = gql`
    query GetUser($id: ID!) {
        user(id: $id) {
            id
            full_name
            first_name
            last_name
            email
            phone
            default_org_id
            org_id
            roles {
                id
                name
            }
            marinas {
                id
                name
            }
            jobs {
                id
                appointment
                appointment_date
                appointment_time
                daily_order
                arrival_instructions
                in_progress
                return_in_progress
                completed_at
                job_type {
                    id
                    name
                }
                marina {
                    id
                    name
                }
                boats {
                    id
                    name
                }
                driver {
                    id
                    full_name
                    phone
                }
                passenger {
                    id
                    full_name
                    phone
                }
                truck {
                    id
                    name
                    license_plate
                }
                customer {
                    id
                    full_name
                    primary_phone
                    secondary_phone
                    location {
                        id
                        full_address
                        address
                        city
                        state
                        zipcode
                    }
                }
                tasks {
                    id
                    updated_at
                    created_at
                    skipped
                    in_progress
                    completed_at
                    answer_count
                    answers {
                        id
                        answer
                        question_id
                        question {
                            id
                            title
                            possible_answers
                            type
                        }
                    }
                }
                pickup_location {
                    id
                    address
                    latitude
                    longitude
                    city
                    state
                    zipcode
                }
                dropoff_location {
                    id
                    address
                    latitude
                    longitude
                    city
                    state
                    zipcode
                }
            }
        }
    }
`;


const GET_USERS = gql`
    query GetUsers {
        users {
            id
            full_name
            first_name
            last_name
            email
            phone
            marinas {
                id
                name
            }
            roles {
                id
                name
            }
            
            created_at
            updated_at
        }
    }
`;


const UPSERT_USER = gql`
    mutation UpsertUser($input: UserInput!) {
        upsertUser(input: $input) {
            id
            full_name
            first_name
            last_name
            email
            phone
            default_org_id
            marinas {
                id
                name
            }
            roles {
                id
                name
            }
            created_at
        }
    }
`

const DELETE_USER = gql`
    mutation DeleteUser($id: ID!) {
        deleteUser(id: $id)
    }
`

const RESET_PASSWORD = gql`
    mutation ResetPassword($id: ID!) {
        resetPassword(id: $id) {
            password
        }
    }
`

export function useUsers(id?: number) {
  const { data, error, loading } = useQuery(GET_USERS, { skip: Boolean(id) })
  const userRes = useQuery(GET_USER, {
    skip: !Boolean(id),
    variables: { id },
  })
  
  const [upsertUserMutation] = useMutation(UPSERT_USER, { refetchQueries: [GET_USERS] })
  const [deleteUserMutation] = useMutation(DELETE_USER, { refetchQueries: [GET_USERS] })
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD)
  
  const users = data?.users ?? []
  
  const upsertUser = async (input: any) => {
    const res = await upsertUserMutation({ variables: { input } })
    return res?.data?.upsertUser ?? {}
  }
  
  const deleteUser = async (id: number) => {
    const res = await deleteUserMutation({ variables: { id } })
    return res?.data?.deleteUser ?? res
  }
  
  const resetPassword = async (id?: number) => {
    const res = await resetPasswordMutation({ variables: { id } })
    return res?.data?.resetPassword
  }
  
  return {
    userRes,
    users,
    upsertUser,
    deleteUser,
    resetPassword,
    error,
    loading
  }
}
