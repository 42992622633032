import React, {useState} from 'react';

import {InputTextarea} from 'primereact/inputtextarea';

import {
  Answer,
  Question,
} from '../../../state-management/types';

import './styles.scss';

interface TextQuestionComponentProps {
  question: Question
  answer?: Answer
  onSelect?: (questionId: number, selectedAnswer: string, answer?: Answer) => void
}

const TextQuestionComponent: React.FC<TextQuestionComponentProps> = ({
  question,
  answer,
  onSelect,
})=> {
  const [selectedAnswer, setSelectedAnswer] = useState<string>(answer?.answer ?? '')
  
  return (
    <div className="flex align-items-center">
      <InputTextarea
        id="question-input-textarea"
        autoResize
        value={selectedAnswer}
        onChange={(e) => {
          setSelectedAnswer(e.target.value);
          if (onSelect && question?.id)
            onSelect(question?.id, e.target.value, answer);
        }}
        rows={5}
        cols={30}
      />
    </div>
  )
};

export default TextQuestionComponent;
