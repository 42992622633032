import React, {useState} from 'react';

import { RadioButton } from 'primereact/radiobutton';

import {
  Answer,
  Question,
} from '../../../state-management/types';

import './styles.scss';

interface MultipleChoiceQuestionComponentProps {
  question: Question
  answer?: Answer
  onSelect?: (questionId: number, selectedAnswer: string, answer?: Answer, image?: File) => void
}

const MultipleChoiceQuestionComponent: React.FC<MultipleChoiceQuestionComponentProps> = ({
  question,
  answer,
  onSelect,
})=> {
  const [selectedAnswer, setSelectedAnswer] = useState<string>(answer?.answer ?? '')
  
  return (
    <>
      {question?.possible_answers?.split(',').map((answerVal: string, i: number) => {
        return (
          <div key={answerVal} className="question-radio-option flex align-items-center">
            <RadioButton
              data-cy={`radio-option-${i}`}
              inputId={answerVal}
              name="possible-answer"
              value={answerVal}
              disabled={false}
              onChange={(e) =>{
                setSelectedAnswer(e.value);
                if (question?.id)
                  onSelect?.(question?.id, e.value, answer);
              }}
              checked={selectedAnswer === answerVal}
            />
            <label htmlFor={answerVal} className="ml-2">{answerVal}</label>
          </div>
        );
      })}
    </>
  )
};

export default MultipleChoiceQuestionComponent;
