import { useToast } from '../providers/toast';


export function useAlert() {
  const showToast = useToast();
  
  
  const showSuccess = (message: string) => {
    showToast('success', 'Success', message);
  }
  
  const showError = (message: string) => {
    console.error(message)
    showToast('error', 'Error', message);
  }
  
  return {
    showSuccess,
    showError,
  }
}
