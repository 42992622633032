import {setKey, setRegion, fromAddress} from 'react-geocode';
import {GOOGLE_MAPS_KEY} from '../utils';

export default function useGeocode() {
  setKey(GOOGLE_MAPS_KEY);
  setRegion('us');
  
  const handleGetGeocode = async ( address: string ) => {
    return fromAddress(address).then((response: any) => {
      const { location } = response.results[0].geometry
      return location
    }, (e: any) => {
      return e
    });
  }
  
  return { getGeocode: handleGetGeocode }
}
