import * as React from 'react';
import {
  ImageList,
  withWidth,
  isWidthUp,
} from '@material-ui/core';
import ImageListItem from '@material-ui/core/ImageListItem';
import './styles.scss';

type breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type columnsType = { [key in breakpoints]?: number };

interface GridItems {
  id: string;
  content: JSX.Element;
  cols: columnsType;
  rows: number;
}

interface WidthProps {
  width: breakpoints;
}

export interface GridProps extends WidthProps {
  items: GridItems[];
}

const Grid: React.FC<GridProps> = ({ width, items }) => {
  const getGridListCols = (cols: columnsType): number => {
    if (cols.xl && isWidthUp('xl', width)) {
      return cols.xl;
    }

    if (cols.lg && isWidthUp('lg', width)) {
      return cols.lg;
    }

    if (cols.md && isWidthUp('md', width)) {
      return cols.md;
    }

    if (cols.sm && isWidthUp('sm', width)) {
      return cols.sm;
    }

    if (cols.xs && isWidthUp('xs', width)) {
      return cols.xs;
    }
    return 1;
  };

  return (
    <ImageList cols={12} className="boatload--grid" rowHeight="auto">
      {items.map(({ cols, rows, id, content }) => (
        <ImageListItem
          cols={getGridListCols(cols)}
          rows={rows}
          key={id}
          className='boatload--grid-tile'
        >
          {content}
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default withWidth()(Grid);
