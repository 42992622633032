import * as React from 'react';
import {Card} from 'primereact/card';
import {Accordion, AccordionTab} from 'primereact/accordion';
import { Carousel } from 'primereact/carousel';

import {Boat, Job, MasterTask, Question} from '../../state-management/types';
import {QuestionComponent} from '../index';

interface JobOutlineProps {
  boat?: Boat
  masterTasks?: MasterTask[]
}

const JobOutlineComponent: React.FC<JobOutlineProps> = ({ masterTasks }) => {
  
  const questionTemplate = (question: Question) => {
    return (
      <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
        <QuestionComponent question={question}/>
      </div>
    );
  };
  
  return (
    <Accordion>
      {masterTasks?.filter((masterTask: MasterTask) => masterTask.questions?.length).map((masterTask: MasterTask) => (
        <AccordionTab key={masterTask?.id} header={masterTask?.name}>
          <Carousel
            value={masterTask.questions}
            numVisible={1}
            numScroll={1}
            itemTemplate={(question: Question) => questionTemplate(question)}
          />
        </AccordionTab>
      ))}
    </Accordion>
  )
};

export default JobOutlineComponent;
