import { Dispatch } from 'react'
import {JobPhase, JobState} from './context'
import {Answer, Boat, Job, MasterTask, Question, Task} from '../types';


export const PREDEPARTURE_PHASE = 0;
export const DEPARTURE_PHASE = 1;
export const ONSITE_PHASE = 2;
export const POST_ARRIVAL_PHASE = 3;
export const RETURN_PHASE = 4;


export const START_JOB = 'START_JOB' as const
export const SET_ACTIVE_BOAT = 'SET_ACTIVE_BOAT' as const
export const NEXT_TASK = 'NEXT_TASK' as const
export const PREV_TASK = 'PREV_TASK' as const
export const SET_PHASE = 'SET_PHASE' as const
export const NEXT_PHASE = 'NEXT_PHASE' as const
export const PREV_PHASE = 'PREV_PHASE' as const

export const SKIP_JOB = 'SKIP_JOB' as const
export const ARRIVED_AT_LAUNCH = 'ARRIVED_AT_LAUNCH' as const
export const SUBMIT_TASK = 'SUBMIT_TASK' as const
export const SUBMIT_ANSWER = 'SUBMIT_ANSWER' as const
export const STORE_ANSWER = 'STORE_ANSWER' as const
export const ADD_TASK = 'ADD_TASK' as const
export const COMPLETE_TASK = 'COMPLETE_TASK' as const
export const COMPLETE_JOB = 'COMPLETE_JOB' as const
export const ERROR_JOB = 'ERROR_JOB' as const

export type JobAction =
  | { type: 'START_JOB', job: Job }
  | { type: 'SET_ACTIVE_BOAT', boat: Boat }
  | { type: 'SKIP_JOB', skipReason: string }
  | { type: 'NEXT_TASK' }
  | { type: 'PREV_TASK' }
  | { type: 'SET_PHASE', index: number }
  | { type: 'NEXT_PHASE' }
  | { type: 'PREV_PHASE' }
  | { type: 'ARRIVED_AT_LAUNCH' }
  | { type: 'ADD_TASK', task: Task }
  | { type: 'STORE_ANSWER', questionId: number, answer?: Answer, selectedAnswer?: string, image?: File }
  | { type: 'SUBMIT_ANSWER', answer: Answer }
  | { type: 'SUBMIT_TASK' }
  | { type: 'COMPLETE_TASK', completedAt: string }
  | { type: 'COMPLETE_JOB' }
  | { type: 'ERROR_JOB'; error: string }

export type JobDispatch = Dispatch<JobAction>

const findFirstIncompleteMasterTask = (masterTasks: MasterTask[], tasks?: Task[]): MasterTask | undefined => {
  return masterTasks?.find((t: MasterTask) => !tasks?.find((task: Task) => task?.master_task?.id === t?.id && task?.completed_at))
}

const findFirstUnansweredQuestionIndex = (masterTask?: MasterTask, task?: Task): number => {
  const questions = masterTask?.questions
  if (!questions) return 0;
  
  const answers = task?.answers
  if (!answers) return 0
  
  return questions?.findIndex((q: Question) => !answers?.find((a: Answer) => a.question_id === q.id)) ?? 0
}

export const reducer = (
  state: JobState,
  action: JobAction
): JobState => {
  
  const taskKey = () => {
    return `${state.currentBoat?.id}-${state.phase.masterTasks[state.phase.currentMasterTaskIndex]?.id}`;
  }
  
  switch (action.type) {
    case START_JOB: {
      const { job } = action
      
      const currentBoat = job?.filtered_boats?.[0]
      
      const masterTasks = currentBoat?.boat_type?.master_tasks?.filter((mt: MasterTask) => mt?.questions?.length) ?? [];
      
      const preDepartureMasterTasks = masterTasks?.filter(
        (t) => t.is_predeparture
      ) ?? [];
      
      const regularMasterTasks = masterTasks?.filter(
        (t) => !t.is_predeparture && !t.is_postarrival
      ) ?? [];
      
      const postArrivalMasterTasks = masterTasks?.filter(
        (t) => t.is_postarrival
      ) ?? [];
      
      const tasks = job?.tasks?.filter((task: Task) => task?.boat?.id === currentBoat?.id) ?? [];
      
      const preDepartureTasks = tasks?.filter(
        (task: Task) => task?.master_task?.is_predeparture
      ) ?? [];
      
      const preDepartureTasksDone = preDepartureMasterTasks?.every(
        (masterTask: MasterTask) =>
          tasks?.find((task: Task) => {
            return task?.master_task?.id === masterTask?.id && task?.completed_at
          }) !== undefined
      );
      
      const regularTasks = tasks?.filter(
        (task: Task) => {
          return !task?.master_task?.is_predeparture && !task?.master_task?.is_postarrival
        }
      ) ?? [];
      
      const regularTasksDone = regularMasterTasks?.every(
        (masterTask: MasterTask) =>
          tasks?.find((task: Task) => {
            return task?.master_task?.id === masterTask?.id && task?.completed_at
          }) !== undefined
      )
      
      const postArrivalTasks = tasks?.filter(
        (task: Task) => task?.master_task?.is_postarrival
      ) ?? [];
      
      const postArrivalTasksDone = postArrivalMasterTasks?.every(
        (masterTask: MasterTask) =>
          tasks?.find((task: Task) => {
            return task?.master_task?.id === masterTask?.id && task?.completed_at
          }) !== undefined
      );
      
      let firstDestination = job?.customer?.location
      let finalInitialLocation = job?.customer?.location
      
      if (job?.job_type?.name?.toLowerCase() === 'dropoff') {
        firstDestination = job?.launch_location
      } else if (job?.job_type?.name?.toLowerCase() === 'pickup') {
        finalInitialLocation = job?.launch_location
      }
      
      const phases: JobPhase[] = [
        {
          index: PREDEPARTURE_PHASE,
          name: 'Pre-Departure',
          location: job?.marina?.location,
          masterTasks: preDepartureMasterTasks,
          tasks: preDepartureTasks,
          currentMasterTaskIndex: 0,
        },
        {
          index: DEPARTURE_PHASE,
          name: 'Departure',
          location: job?.marina?.location,
          nextLocation: firstDestination,
          masterTasks: [],
          tasks: [],
          currentMasterTaskIndex: 0,
        },
        {
          index: ONSITE_PHASE,
          name: 'On-Site',
          location: finalInitialLocation,
          masterTasks: regularMasterTasks,
          tasks: regularTasks,
          currentMasterTaskIndex: 0,
        },
        {
          index: POST_ARRIVAL_PHASE,
          name: 'Post-Arrival',
          location: job?.marina?.location,
          masterTasks: postArrivalMasterTasks,
          tasks: postArrivalTasks,
          currentMasterTaskIndex: 0,
        },
        {
          index: RETURN_PHASE,
          name: 'Return',
          location: finalInitialLocation,
          nextLocation: job?.marina?.location,
          masterTasks: [],
          tasks: [],
          currentMasterTaskIndex: 0,
        },
      ];
      
      let phase: JobPhase;
      let currentMasterTasks: MasterTask[] = [];
      let currentMasterTaskIndex = 0;
      let currentMasterTask: MasterTask | undefined;
      
      if (!preDepartureTasksDone) {
        phase = phases[PREDEPARTURE_PHASE];
        currentMasterTasks = preDepartureMasterTasks;
        currentMasterTask = findFirstIncompleteMasterTask(preDepartureMasterTasks, tasks);
        currentMasterTaskIndex = currentMasterTasks?.findIndex((masterTask: MasterTask) => masterTask?.id === currentMasterTask?.id) ?? 0;
        phases[PREDEPARTURE_PHASE].currentMasterTaskIndex = currentMasterTaskIndex;
      } else if (!regularTasksDone) {
        phase = phases[DEPARTURE_PHASE];
        currentMasterTasks = [];
        currentMasterTask = undefined;
        
        if (job?.arrived_at_pickup) {
          phase = phases[ONSITE_PHASE];
          currentMasterTasks = regularMasterTasks;
          currentMasterTask = findFirstIncompleteMasterTask(regularMasterTasks, tasks);
          currentMasterTaskIndex = currentMasterTasks?.findIndex((masterTask: MasterTask) => masterTask?.id === currentMasterTask?.id) ?? 0;
          phases[ONSITE_PHASE].currentMasterTaskIndex = currentMasterTaskIndex;
        }
      } else if (!postArrivalTasksDone) {
        phase = phases[POST_ARRIVAL_PHASE];
        currentMasterTasks = postArrivalMasterTasks;
        currentMasterTask = findFirstIncompleteMasterTask(postArrivalMasterTasks, tasks);
        currentMasterTaskIndex = currentMasterTasks?.findIndex((masterTask: MasterTask) => masterTask?.id === currentMasterTask?.id) ?? 0;
        phases[POST_ARRIVAL_PHASE].currentMasterTaskIndex = currentMasterTaskIndex;
      } else {
        phase = phases[RETURN_PHASE];
        currentMasterTasks = [];
        currentMasterTask = undefined;
      }
      
      currentMasterTask = findFirstIncompleteMasterTask(currentMasterTasks, tasks);
      currentMasterTaskIndex = currentMasterTasks?.findIndex((masterTask: MasterTask) => masterTask?.id === currentMasterTask?.id) ?? 0;
      phases[phase.index].currentMasterTaskIndex = currentMasterTaskIndex;
      
      let currentTask = tasks?.find((task: Task) => {
        return task?.master_task?.id === currentMasterTask?.id
      });
      
      if (currentMasterTask?.is_predeparture) {
        phases[PREDEPARTURE_PHASE].currentMasterTaskIndex = preDepartureMasterTasks?.findIndex((masterTask: MasterTask) => {
          return masterTask?.id === currentMasterTask?.id
        });
        
      } else if (!currentMasterTask?.is_predeparture && !currentMasterTask?.is_postarrival) {
        phases[ONSITE_PHASE].currentMasterTaskIndex = regularMasterTasks?.findIndex((masterTask: MasterTask) => {
          return masterTask?.id === currentMasterTask?.id
        });
        
      } else if (currentMasterTask?.is_postarrival) {
        phases[POST_ARRIVAL_PHASE].currentMasterTaskIndex = postArrivalMasterTasks?.findIndex((masterTask: MasterTask) => {
          return masterTask?.id === currentMasterTask?.id
        }) ?? 0;
      }
      
      let currentQuestionIndex = 0;
      if (currentTask)
        currentQuestionIndex = findFirstUnansweredQuestionIndex(currentMasterTask, currentTask) ?? 0;
      else
        currentQuestionIndex = 0;
      
      if (phases[phase.index].currentMasterTaskIndex === -1)
        phases[phase.index].currentMasterTaskIndex = 0;
      
      if (phases[PREDEPARTURE_PHASE].currentMasterTaskIndex === -1)
        phases[PREDEPARTURE_PHASE].currentMasterTaskIndex = 0;
      
      if (phases[ONSITE_PHASE].currentMasterTaskIndex === -1)
        phases[ONSITE_PHASE].currentMasterTaskIndex = 0;
      
      if (phases[POST_ARRIVAL_PHASE].currentMasterTaskIndex === -1)
        phases[POST_ARRIVAL_PHASE].currentMasterTaskIndex = 0;
      
      let currentQuestion = currentMasterTask?.questions?.[currentQuestionIndex];
      
      return {
        ...state,
        job,
        phases,
        phase,
        currentBoat,
        
        masterTasks,
        currentTask,
        currentQuestionIndex,
        currentQuestion,
        
        storedTasks: new Map(),
        arrivedAtLaunch: Boolean(job?.arrived_at_pickup),
        error: null,
        loading: false
      };
    }
    
    case SET_ACTIVE_BOAT: {
      const { boat } = action;
      return {
        ...state,
        currentBoat: boat
      };
    }
    
    case SKIP_JOB: {
      const { skipReason } = action;
      return {
        ...state,
        job: {
          ...state.job,
          skip_reason: skipReason
        },
      };
    }
    
    case SET_PHASE: {
      const { index } = action;
      return {
        ...state,
        phase: state.phases?.[index]
      };
    }
    
    case NEXT_PHASE: {
      if (state.phase.index === state.phases.length - 1)
        return state;
      
      const newState = { ...state };
      
      newState.phase = state.phases?.[state.phase.index + 1];
      newState.phases[newState.phase.index] = newState.phase
      return newState;
    }
    
    case PREV_PHASE: {
      if (state.phase.index <= 0)
        return state;
      
      const newState = { ...state };
      
      newState.phase = state.phases?.[state.phase.index - 1];
      newState.phases[newState.phase.index] = newState.phase;
      return newState;
    }
    
    case NEXT_TASK: {
      const newState = {
        ...state,
        phases: [...state.phases],
        phase: { ...state.phase },
      };
      
      if (state.phase.masterTasks.length === 0 || state.phase.currentMasterTaskIndex === state.phase.masterTasks.length - 1) {
        if (state.phase.index < state.phases.length - 1) {
          newState.phase = { ...newState.phases[newState.phase.index + 1] };
          newState.phase.currentMasterTaskIndex = 0;
        } else {
          return state;
        }
      } else {
        newState.phase.currentMasterTaskIndex += 1;
      }
      
      newState.currentTask = state.job?.tasks?.find((task: Task) => {
        return task?.master_task?.id === newState.phase.masterTasks[newState.phase.currentMasterTaskIndex]?.id && task?.boat?.id === state.currentBoat?.id
      });
      
      newState.phases[newState.phase.index] = newState.phase;
      return newState;
    }
    
    case PREV_TASK: {
      const newState = {
        ...state,
        phases: [...state.phases],
        phase: { ...state.phase },
      };
      
      if (state.phase.masterTasks.length === 0 || state.phase.currentMasterTaskIndex === 0) {
        if (state.phase.index > 0) {
          newState.phase = { ...newState.phases[newState.phase.index - 1] };
          newState.phase.currentMasterTaskIndex = newState.phase.masterTasks.length - 1;
        }
      } else {
        newState.phase.currentMasterTaskIndex -= 1;
      }
      
      newState.currentTask = state.job?.tasks?.find((task: Task) => {
        return task?.master_task?.id === newState.phase.masterTasks[newState.phase.currentMasterTaskIndex]?.id && task?.boat?.id === state.currentBoat?.id
      });
      
      newState.phases[newState.phase.index] = newState.phase;
      return newState;
    }
    
    case STORE_ANSWER: {
      const { questionId, answer, selectedAnswer, image } = action;
      const { storedTasks } = state;
      
      if (!storedTasks.get(taskKey())) {
        let answerMap = new Map<number, any>();
        storedTasks.set(taskKey(), answerMap);
      }
      
      const answerData = {
        task_id: state.currentTask?.id,
        question_id: questionId,
        answer,
        image,
        selectedAnswer,
      };
      
      storedTasks.get(taskKey())?.set(questionId, answerData);
      
      return {
        ...state,
        storedTasks,
      }
    }
    
    case SUBMIT_TASK: {
      if (!state.currentTask)
        return state
      
      const { storedTasks }  = { ...state };
      
      storedTasks.delete(taskKey());
      
      return {
        ...state,
        storedTasks,
      }
    }
    
    case ARRIVED_AT_LAUNCH: {
      return {
        ...state,
        arrivedAtLaunch: true,
        loading: false,
      }
    }
    
    case SUBMIT_ANSWER: {
      const { answer } = action;
      const newAnswers = [...state.currentTask?.answers ?? [], answer];
      console.log('new answers', newAnswers)
      return {
        ...state,
        currentTask: {
          ...state.currentTask,
          answers: newAnswers,
          answer_count: newAnswers.length,
        },
        job: {
          ...state.job,
          tasks: state.job?.tasks?.map((task: Task) => {
            if (task?.id === state.currentTask?.id)
              return { ...task, answers: [...task.answers ?? [], answer] }
            return task
          }),
        },
        loading: false,
      }
    }
    
    case 'ADD_TASK': {
      const { task } = action
      let { phases, phase } = state;
      
      phase.tasks = [...phase.tasks, task];
      phases[phase.index] = phase;
      
      return {
        ...state,
        job: {
          ...state.job,
          tasks: [...state.job?.tasks ?? [], task],
        },
        currentTask: task,
        phase,
        loading: false,
      }
    }
    
    case COMPLETE_TASK: {
      const { completedAt } = action;
      return {
        ...state,
        currentTask: { ...state.currentTask, completed_at: completedAt },
        loading: false,
      }
    }
    
    case COMPLETE_JOB: {
      return {
        ...state,
        completed: true,
        loading: false
      }
    }

    case ERROR_JOB: {
      const { error } = action
      return { ...state, error, loading: false }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}
