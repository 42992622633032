import {MenuItemProps} from './index';
import {User, Role} from '../../state-management/types';

export const shouldRenderListGroup = (items?: MenuItemProps[], user?: User) => {
  if (!items?.length)
    return true
  
  for (let i of items)
    if (shouldRenderMenuItem(i, user)) return true
  
  return false
}

export const shouldRenderMenuItem = (item: MenuItemProps, user?: User): boolean => {
  const missingItemRole = item.roleKeys && !hasRoles(['superadmin', ...item.roleKeys], user?.roles)
  const missingItemPermission = item.permissionsKey && !hasPermission(item.permissionsKey, user?.permissions)
  
  return !Boolean(missingItemRole) && !Boolean(missingItemPermission)
}

export const hasRoles = (roleKeys: string[], userRoles?: Role[]) => {
  for (let r of roleKeys)
    if (userRoles?.map(r => r.name).includes(r))
      return true
  
  return false
}

export const onlyHasRoles = (roleKeys: string[], userRoles?: Role[]) => {
  if (userRoles?.length !== roleKeys.length)
    return false
  
  for (let r of roleKeys)
    if (!userRoles?.map(r => r.name).includes(r))
      return false
  
  return true
}

export const hasPermission = (permission: string, userPermissions?: string[]): boolean => {
  for (let p of userPermissions || [])
    if (p.includes(permission))
      return true
  
  return false
}
